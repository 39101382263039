import axios from 'axios';
import type { AxiosProgressEvent } from 'axios';

import { encodeSlashes } from '@apple/utils/url';

import {
	productAuditSchema,
	productExcelResultSchema,
	productSchema,
} from '../../product/models/management';
import type { Product, ProductAudit, ProductExcelResult } from '../../product/models/management';
import type { ProductKey } from '../../product/models/product';

/** Service wrapper for:
 * - AppleBrandedPrograms.Web.Features.Catalog.AppleBrandedProductsController
 * - Manzanita.Web.Features.Catalog.ProductsController
 * - Manzanita.Web.Features.Catalog.ProductImagesController
 */

/**
 * Gets the product with the given ID.
 * @see AppleBrandedPrograms.Web.Features.Catalog.AppleBrandedProductsController
 */
export async function getProduct(itemId: ProductKey): Promise<Product> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}`);
	return productSchema.parse(response.data);
}

/**
 * Saves a new or existing product with the given ID and information.
 * @param product - The product to save.
 * @param auditComment - Optional. Only required if the audit filter is enabled on the server.
 * @see AppleBrandedPrograms.Web.Features.Catalog.AppleBrandedProductsController
 */
export async function saveProduct(product: Product, auditComment?: string): Promise<void> {
	await axios.put(`/api/management/products/${encodeSlashes(product.itemId)}`, product, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/**
 * Saves a collection of new, existing, or deleted products.
 * @param products - The products to save.
 * @param auditComment - Optional. Only required if the audit filter is enabled on the server.
 * @see AppleBrandedPrograms.Web.Features.Catalog.AppleBrandedProductsController
 */
export async function saveProducts(products: Product[], auditComment?: string): Promise<void> {
	await axios.post('/api/management/products', products, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/**
 * Deletes the product with the given ID.
 * @see AppleBrandedPrograms.Web.Features.Catalog.AppleBrandedProductsController
 */
export async function deleteProduct(itemId: ProductKey, auditComment?: string): Promise<void> {
	await axios.delete(`/api/management/products/${encodeSlashes(itemId)}`, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/**
 * Deletes the products with the given IDs.
 * @see AppleBrandedPrograms.Web.Features.Catalog.AppleBrandedProductsController
 */
export async function deleteProducts(itemIds: ProductKey[], auditComment?: string): Promise<void> {
	if (itemIds.length === 0) {
		return;
	}

	await axios.post('/api/management/products/delete-multiple', itemIds, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/**
 * This method is implemented in the client projects, but the URL is the same in both projects.
 * @see AppleBrandedPrograms.Web.Features.Catalog.AppleBrandedProductsController
 */
export async function getProductAudits(itemId: string): Promise<ProductAudit[]> {
	const response = await axios.get(`/api/management/products/${encodeSlashes(itemId)}/audits`);
	return productAuditSchema.array().parse(response.data);
}

/**
 * Uploads a product bulk management Excel file to be converted into products.
 * @see AppleBrandedPrograms.Web.Features.Catalog.ProductsExcelController
 */
export async function uploadProducts(
	file: File,
	onUploadProgress?: (event: AxiosProgressEvent) => void,
): Promise<ProductExcelResult> {
	const response = await axios.post<unknown>('/api/management/products/bulk/excel', file, {
		headers: { 'Content-Type': file.type },
		onUploadProgress,
	});

	return productExcelResultSchema.parse(response.data);
}

/** @see AppleBrandedPrograms.Web.Features.Catalog.ProductsExcelController */
export function getProductManagementTemplateUrl(): string {
	return '/api/management/products/bulk/excel/generate';
}
