import { z } from 'zod';

import { CountryAddressRequirement } from '@apple/features/address/models';
import { plantRequestSchema } from '@apple/features/plants/models';
import { countryCodeSchema } from '@apple/utils/globalization/models';
import type { PlantRequest } from '@apple/features/plants/models';
import type { CountryCode } from '@apple/utils/globalization/models';

export const countryKeys = {
	all: ['country'],
	country: (countryCode: CountryCode) => ['country', countryCode],
	supported: ['country', 'supported'],
} as const;

/** AppleBrandedPrograms.Web.Areas.Admin.ApiControllers.CountryAdminApiController.CountryModel*/
export interface Country {
	name: string;
	code: CountryCode;
	cultureCode: string;
	plant?: PlantRequest;
	currencyCode: string;
	addressRequirementType: CountryAddressRequirement | null;
	isEligibleForReturnOrders: boolean;
}

export interface CountryFilters extends Partial<Country> {}

export const AddressRequirementSchema = z.enum(['Local', 'LocalAndInternational']);

/** AppleBrandedPrograms.Web.Areas.Admin.ApiControllers.CountryAdminApiController.CountryModel*/
export const countrySchema = z.object({
	name: z.string(),
	code: countryCodeSchema,
	cultureCode: z.string(),
	plant: plantRequestSchema.optional(),
	currencyCode: z.string(),
	addressRequirementType: z
		.union([z.coerce.number(), AddressRequirementSchema])
		.nullable()
		//backend sometimes returns Local and LocalAndInternational instead of 0 and 1
		.transform(value => {
			if (value === null) return null;
			if (typeof value === 'string') {
				if (value === 'Local') return CountryAddressRequirement.Local;
				if (value === 'LocalAndInternational')
					return CountryAddressRequirement.LocalAndInternational;
			}
			if (typeof value === 'number') {
				return value as CountryAddressRequirement;
			}

			throw new Error('Invalid addressRequirementType value');
		}) as z.ZodType<CountryAddressRequirement>,
	isEligibleForReturnOrders: z.boolean(),
}) satisfies z.ZodType<Country>;

export const countryFiltersSchema = countrySchema.partial() as z.ZodType<CountryFilters>;

export const countryRequestSchema = countrySchema
	.extend({
		code: countryCodeSchema.nullable(),
		country: countrySchema.shape.name,
		currency: countrySchema.shape.currencyCode,
		language: countrySchema.shape.cultureCode,
	})
	.omit({ name: true, currencyCode: true, cultureCode: true });

export type CountryRequest = z.infer<typeof countryRequestSchema>;
