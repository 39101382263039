import { useMemo } from 'react';

import { icons } from '@apple/assets';
import { useAuthContext } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import type { DashboardCard } from '@apple/ui/shell/components/Dashboard';

export function useAppleChannelAdminDashboard() {
	const { t } = useTranslation('admin-dashboard');
	const { filterByPermissions } = useAuthContext();

	const cards = useMemo(() => {
		return filterByPermissions<DashboardCard>([
			{
				title: t('cards.orderApproval'),
				icon: icons.Success,
				to: '/approvals/orders',
				datatestId: 'order-approval',
				color: '#61bb46',
				requirePermission:
					'AppleBrandedPrograms.Security.Features.LocationApprovalManagement',
			},
			{
				title: t('cards.products'),
				icon: icons.Products,
				to: '/manage/products',
				datatestId: 'admin-product',
				color: '#fdb827',
				requirePermission: 'Manzanita.Security.Features.ProductManagement.Read',
			},
			{
				title: t('cards.uploadDistro'),
				icon: icons.Upload,
				to: '/distro/upload',
				datatestId: 'distro-upload',
				color: '#f5821f',
				requirePermission: 'Manzanita.Security.Orders.CanPlaceDistroOrders',
			},
			{
				title: t('cards.users'),
				icon: icons.Users,
				to: '/manage/users',
				datatestId: 'admin-users',
				color: '#e03a3e',
				requirePermission: 'Manzanita.Security.Features.UserManagement.Read',
			},
			{
				title: t('cards.distroHistory'),
				icon: icons.Calendar,
				to: '/distro/history',
				datatestId: 'distro-history',
				color: '#963d97',
				requirePermission: 'Manzanita.Security.Features.DistroOrderHistory',
			},
			{
				title: t('cards.locationApprovals'),
				icon: icons.LocationApproval,
				to: '/approvals/location',
				datatestId: 'location-approvals',
				color: '#009ddc',
				requirePermission:
					'AppleBrandedPrograms.Security.Features.LocationApprovalManagement',
			},
		]);
	}, [filterByPermissions, t]);

	return { cards };
}
