import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/tiptap/styles.css';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { MantineProvider, MantineThemeContext } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { QueryClientProvider, useQuery } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import log from 'loglevel';
import type { PropsWithChildren } from 'react';

import { queryClient, router } from '@apple/channel/router';
import { AuthProvider, useAuthContext } from '@apple/features/auth';
import { cartQueryOptions } from '@apple/features/cart-channel/queries';
import { CartIdProvider } from '@apple/features/cart/contexts/cart-id';
import { useCurrentUserLanguage } from '@apple/features/user';
import { appleTheme } from '@apple/theme/apple';
import { appleCssVariables } from '@apple/theme/variables';
import { configApp } from '@apple/utils/config';
import type { AppleChannelRouter } from '@apple/channel/router';

declare module '@tanstack/react-router' {
	interface Register {
		router: AppleChannelRouter;
	}
}

async function run() {
	try {
		const { telemetry, env } = await configApp();

		// eslint-disable-next-line no-inner-declarations
		function App() {
			const auth = useAuthContext();
			const lang = useCurrentUserLanguage();

			return <RouterProvider router={router} context={{ auth, lang }} />;
		}

		// eslint-disable-next-line no-inner-declarations
		function AppProviders({ children }: PropsWithChildren) {
			return (
				<AppInsightsContext.Provider value={telemetry}>
					<QueryClientProvider client={queryClient}>
						<MantineProvider
							theme={appleTheme}
							cssVariablesResolver={appleCssVariables}
							defaultColorScheme='auto'
						>
							<MantineThemeContext.Consumer>
								{theme => (
									<>
										<Notifications
											position='top-right'
											mt={theme?.other.headerHeight}
										/>
										<ModalsProvider>
											<AuthProvider router={router}>
												<AuthedProviders>{children}</AuthedProviders>
											</AuthProvider>
										</ModalsProvider>
									</>
								)}
							</MantineThemeContext.Consumer>
						</MantineProvider>
					</QueryClientProvider>
				</AppInsightsContext.Provider>
			);
		}

		// eslint-disable-next-line no-inner-declarations
		function AuthedProviders({ children }: PropsWithChildren) {
			const auth = useAuthContext();
			const cartId = useQuery(cartQueryOptions.cartId(auth.authenticated));

			return (
				<CartIdProvider value={cartId.data?.bulkOrderId ?? null} loading={cartId.isLoading}>
					{children}
				</CartIdProvider>
			);
		}

		const rootElement = document.getElementById('root');
		if (!rootElement) {
			throw new Error('Root element not found');
		}

		ReactDOM.createRoot(rootElement).render(
			env.APPLE_REACT_STRICT_MODE ? (
				<StrictMode>
					<AppProviders>
						<App />
					</AppProviders>
				</StrictMode>
			) : (
				<AppProviders>
					<App />
				</AppProviders>
			),
		);
	} catch (err) {
		log.error('Error starting app:', err);
	}
}

void run();
