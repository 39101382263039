import { z } from 'zod';

import { countryCodeSchema, currencyCodeSchema } from '@apple/utils/globalization';
import type { CountryCode } from '@apple/utils/globalization';

export type Plant = z.infer<typeof plantSchema>;

/**Manzanita.Web.Models.PlantModel */
export interface PlantRequest
	extends Omit<
		Plant,
		'description' | 'warehouseCode' | 'countryCodes' | 'currencyCode' | 'warehouseLocationId'
	> {
	description?: string | null;
	warehouseCode?: string;
	countryCodes?: CountryCode[];
	currencyCode?: string;
	warehouseLocationId: number;
}

/** Manzanita.Web.Models.SimplePlantModel */
export const plantSchema = z.object({
	warehouseLocationId: z.string().transform(Number),
	name: z.string(),
	description: z.string(),
	warehouseCode: z.string(),
	abbreviation: z.string(),
	currencyCode: currencyCodeSchema,
	countryCodes: z.array(countryCodeSchema),
});

export const plantRequestSchema = plantSchema
	.omit({ description: true, warehouseCode: true, countryCodes: true, currencyCode: true })
	.extend({
		description: z.string().nullable().optional(),
		warehouseCode: z.string().optional(),
		countryCodes: z.array(countryCodeSchema).optional(),
		currencyCode: currencyCodeSchema.optional(),
		warehouseLocationId: z.coerce.number(),
	}) satisfies z.ZodType<PlantRequest>;
