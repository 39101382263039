/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as AuthedRouteImport } from './routes/_authed/route';
import { Route as AuthedIndexImport } from './routes/_authed/index';
import { Route as AuthedReturnsImport } from './routes/_authed/returns';
import { Route as AuthedProfileImport } from './routes/_authed/profile';
import { Route as AuthedHelpImport } from './routes/_authed/help';
import { Route as AuthedAddressBookImport } from './routes/_authed/address-book';
import { Route as publicSessionExpiredImport } from './routes/(public)/session-expired';
import { Route as publicResetPasswordImport } from './routes/(public)/reset-password';
import { Route as publicPrivacyImport } from './routes/(public)/privacy';
import { Route as publicLogoutImport } from './routes/(public)/logout';
import { Route as publicLoginImport } from './routes/(public)/login';
import { Route as publicForgotUsernameImport } from './routes/(public)/forgot-username';
import { Route as publicForgotPasswordImport } from './routes/(public)/forgot-password';
import { Route as publicFirstLoginImport } from './routes/(public)/first-login';
import { Route as errorsNotFoundImport } from './routes/(errors)/not-found';
import { Route as AuthedAdminRouteImport } from './routes/_authed/_admin/route';
import { Route as AuthedCartIndexImport } from './routes/_authed/cart.index';
import { Route as AuthedReturnsOrderNumberImport } from './routes/_authed/returns_.$orderNumber';
import { Route as AuthedOrdersSearchImport } from './routes/_authed/orders.search';
import { Route as AuthedOrdersPoNumberImport } from './routes/_authed/orders.$poNumber';
import { Route as AuthedCartStepImport } from './routes/_authed/cart.$step';
import { Route as AuthedAdminManageImport } from './routes/_authed/_admin/manage';
import { Route as publicResetPasswordFormImport } from './routes/(public)/reset-password_.form';
import { Route as publicFirstLoginFormImport } from './routes/(public)/first-login_.form';
import { Route as AuthedCartCartIdConfirmationImport } from './routes/_authed/cart.$cartId.confirmation';
import { Route as AuthedAdminReportsUsersImport } from './routes/_authed/_admin/reports/users';
import { Route as AuthedAdminReportsSupportRequestsImport } from './routes/_authed/_admin/reports/supportRequests';
import { Route as AuthedAdminReportsProductsImport } from './routes/_authed/_admin/reports/products';
import { Route as AuthedAdminReportsLocationFeedImport } from './routes/_authed/_admin/reports/location-feed';
import { Route as AuthedAdminReportsEmailsImport } from './routes/_authed/_admin/reports/emails';
import { Route as AuthedAdminManageUsersImport } from './routes/_authed/_admin/manage.users';
import { Route as AuthedAdminManageSubprogramsImport } from './routes/_authed/_admin/manage.subprograms';
import { Route as AuthedAdminManageProgramsImport } from './routes/_authed/_admin/manage.programs';
import { Route as AuthedAdminManageProductsImport } from './routes/_authed/_admin/manage.products';
import { Route as AuthedAdminManagePlantsImport } from './routes/_authed/_admin/manage.plants';
import { Route as AuthedAdminManageMerchandisersImport } from './routes/_authed/_admin/manage.merchandisers';
import { Route as AuthedAdminManageLocationsImport } from './routes/_authed/_admin/manage.locations';
import { Route as AuthedAdminManageLineOfBusinessImport } from './routes/_authed/_admin/manage.line-of-business';
import { Route as AuthedAdminManageCustomersImport } from './routes/_authed/_admin/manage.customers';
import { Route as AuthedAdminManageCurrenciesImport } from './routes/_authed/_admin/manage.currencies';
import { Route as AuthedAdminManageCountriesImport } from './routes/_authed/_admin/manage.countries';
import { Route as AuthedAdminManageAddressesImport } from './routes/_authed/_admin/manage.addresses';
import { Route as AuthedAdminDistroUploadImport } from './routes/_authed/_admin/distro.upload';
import { Route as AuthedAdminDistroHistoryImport } from './routes/_authed/_admin/distro.history';
import { Route as AuthedAdminContentProjectsImport } from './routes/_authed/_admin/content.projects';
import { Route as AuthedAdminContentFileManagerImport } from './routes/_authed/_admin/content.file-manager';
import { Route as AuthedAdminContentFaqImport } from './routes/_authed/_admin/content.faq';
import { Route as AuthedAdminContentAnnouncementsImport } from './routes/_authed/_admin/content.announcements';
import { Route as AuthedAdminApprovalsOrdersImport } from './routes/_authed/_admin/approvals.orders';
import { Route as AuthedAdminApprovalsLocationImport } from './routes/_authed/_admin/approvals.location';
import { Route as AuthedAdminReportsSupportRequestsEmailIdImport } from './routes/_authed/_admin/reports/supportRequests_.$emailId';
import { Route as AuthedAdminReportsLocationFeedLocationIdImport } from './routes/_authed/_admin/reports/location-feed_.$locationId';
import { Route as AuthedAdminReportsEmailsEmailIdImport } from './routes/_authed/_admin/reports/emails_.$emailId';
import { Route as AuthedAdminManageUsersAddImport } from './routes/_authed/_admin/manage.users_.add';
import { Route as AuthedAdminManageUsersUserNameImport } from './routes/_authed/_admin/manage.users_.$userName';
import { Route as AuthedAdminManageSubprogramsAddImport } from './routes/_authed/_admin/manage.subprograms_.add';
import { Route as AuthedAdminManageProgramsAddImport } from './routes/_authed/_admin/manage.programs_.add';
import { Route as AuthedAdminManageProgramsProgramIdImport } from './routes/_authed/_admin/manage.programs_.$programId';
import { Route as AuthedAdminManageProductsAddImport } from './routes/_authed/_admin/manage.products_.add';
import { Route as AuthedAdminManageProductsItemIdImport } from './routes/_authed/_admin/manage.products_.$itemId';
import { Route as AuthedAdminManageMerchandisersAddImport } from './routes/_authed/_admin/manage.merchandisers_.add';
import { Route as AuthedAdminManageMerchandisersLocationIdImport } from './routes/_authed/_admin/manage.merchandisers_.$locationId';
import { Route as AuthedAdminManageCustomersAddImport } from './routes/_authed/_admin/manage.customers_.add';
import { Route as AuthedAdminManageCustomersCustomerCodeImport } from './routes/_authed/_admin/manage.customers_.$customerCode';
import { Route as AuthedAdminManageCountriesAddImport } from './routes/_authed/_admin/manage.countries_.add';
import { Route as AuthedAdminManageCountriesCountryCodeImport } from './routes/_authed/_admin/manage.countries_.$countryCode';
import { Route as AuthedAdminManageAddressesAddressBookIdImport } from './routes/_authed/_admin/manage.addresses_.$addressBookId';
import { Route as AuthedAdminContentFileManagerUploadImport } from './routes/_authed/_admin/content.file-manager_.upload';
import { Route as AuthedAdminContentFileManagerDetailImport } from './routes/_authed/_admin/content.file-manager_.detail';
import { Route as AuthedAdminContentFaqAddImport } from './routes/_authed/_admin/content.faq_.add';
import { Route as AuthedAdminContentFaqFaqIdImport } from './routes/_authed/_admin/content.faq_.$faqId';
import { Route as AuthedAdminContentAnnouncementsAddImport } from './routes/_authed/_admin/content.announcements_.add';
import { Route as AuthedAdminContentAnnouncementsAnnouncementIdImport } from './routes/_authed/_admin/content.announcements_.$announcementId';
import { Route as AuthedAdminApprovalsOrdersPoNumberImport } from './routes/_authed/_admin/approvals.orders_.$poNumber';
import { Route as AuthedAdminApprovalsLocationUserIdImport } from './routes/_authed/_admin/approvals.location_.$userId';
import { Route as AuthedAdminManageSubprogramsCustomerIdCountryCodeImport } from './routes/_authed/_admin/manage.subprograms_.$customerId_.$countryCode';

// Create/Update Routes

const AuthedRouteRoute = AuthedRouteImport.update({
  id: '/_authed',
  getParentRoute: () => rootRoute,
} as any);

const AuthedIndexRoute = AuthedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedReturnsRoute = AuthedReturnsImport.update({
  id: '/returns',
  path: '/returns',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedProfileRoute = AuthedProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedHelpRoute = AuthedHelpImport.update({
  id: '/help',
  path: '/help',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedAddressBookRoute = AuthedAddressBookImport.update({
  id: '/address-book',
  path: '/address-book',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const publicSessionExpiredRoute = publicSessionExpiredImport.update({
  id: '/(public)/session-expired',
  path: '/session-expired',
  getParentRoute: () => rootRoute,
} as any);

const publicResetPasswordRoute = publicResetPasswordImport.update({
  id: '/(public)/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any);

const publicPrivacyRoute = publicPrivacyImport.update({
  id: '/(public)/privacy',
  path: '/privacy',
  getParentRoute: () => rootRoute,
} as any);

const publicLogoutRoute = publicLogoutImport.update({
  id: '/(public)/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any);

const publicLoginRoute = publicLoginImport.update({
  id: '/(public)/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any);

const publicForgotUsernameRoute = publicForgotUsernameImport.update({
  id: '/(public)/forgot-username',
  path: '/forgot-username',
  getParentRoute: () => rootRoute,
} as any);

const publicForgotPasswordRoute = publicForgotPasswordImport.update({
  id: '/(public)/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => rootRoute,
} as any);

const publicFirstLoginRoute = publicFirstLoginImport.update({
  id: '/(public)/first-login',
  path: '/first-login',
  getParentRoute: () => rootRoute,
} as any);

const errorsNotFoundRoute = errorsNotFoundImport.update({
  id: '/(errors)/not-found',
  path: '/not-found',
  getParentRoute: () => rootRoute,
} as any);

const AuthedAdminRouteRoute = AuthedAdminRouteImport.update({
  id: '/_admin',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedCartIndexRoute = AuthedCartIndexImport.update({
  id: '/cart/',
  path: '/cart/',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedReturnsOrderNumberRoute = AuthedReturnsOrderNumberImport.update({
  id: '/returns_/$orderNumber',
  path: '/returns/$orderNumber',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedOrdersSearchRoute = AuthedOrdersSearchImport.update({
  id: '/orders/search',
  path: '/orders/search',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedOrdersPoNumberRoute = AuthedOrdersPoNumberImport.update({
  id: '/orders/$poNumber',
  path: '/orders/$poNumber',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedCartStepRoute = AuthedCartStepImport.update({
  id: '/cart/$step',
  path: '/cart/$step',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedAdminManageRoute = AuthedAdminManageImport.update({
  id: '/manage',
  path: '/manage',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const publicResetPasswordFormRoute = publicResetPasswordFormImport.update({
  id: '/(public)/reset-password_/form',
  path: '/reset-password/form',
  getParentRoute: () => rootRoute,
} as any);

const publicFirstLoginFormRoute = publicFirstLoginFormImport.update({
  id: '/(public)/first-login_/form',
  path: '/first-login/form',
  getParentRoute: () => rootRoute,
} as any);

const AuthedCartCartIdConfirmationRoute =
  AuthedCartCartIdConfirmationImport.update({
    id: '/cart/$cartId/confirmation',
    path: '/cart/$cartId/confirmation',
    getParentRoute: () => AuthedRouteRoute,
  } as any);

const AuthedAdminReportsUsersRoute = AuthedAdminReportsUsersImport.update({
  id: '/reports/users',
  path: '/reports/users',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminReportsSupportRequestsRoute =
  AuthedAdminReportsSupportRequestsImport.update({
    id: '/reports/supportRequests',
    path: '/reports/supportRequests',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminReportsProductsRoute = AuthedAdminReportsProductsImport.update(
  {
    id: '/reports/products',
    path: '/reports/products',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any,
);

const AuthedAdminReportsLocationFeedRoute =
  AuthedAdminReportsLocationFeedImport.update({
    id: '/reports/location-feed',
    path: '/reports/location-feed',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminReportsEmailsRoute = AuthedAdminReportsEmailsImport.update({
  id: '/reports/emails',
  path: '/reports/emails',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminManageUsersRoute = AuthedAdminManageUsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManageSubprogramsRoute =
  AuthedAdminManageSubprogramsImport.update({
    id: '/subprograms',
    path: '/subprograms',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageProgramsRoute = AuthedAdminManageProgramsImport.update({
  id: '/programs',
  path: '/programs',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManageProductsRoute = AuthedAdminManageProductsImport.update({
  id: '/products',
  path: '/products',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManagePlantsRoute = AuthedAdminManagePlantsImport.update({
  id: '/plants',
  path: '/plants',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManageMerchandisersRoute =
  AuthedAdminManageMerchandisersImport.update({
    id: '/merchandisers',
    path: '/merchandisers',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageLocationsRoute = AuthedAdminManageLocationsImport.update(
  {
    id: '/locations',
    path: '/locations',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any,
);

const AuthedAdminManageLineOfBusinessRoute =
  AuthedAdminManageLineOfBusinessImport.update({
    id: '/line-of-business',
    path: '/line-of-business',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageCustomersRoute = AuthedAdminManageCustomersImport.update(
  {
    id: '/customers',
    path: '/customers',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any,
);

const AuthedAdminManageCurrenciesRoute =
  AuthedAdminManageCurrenciesImport.update({
    id: '/currencies',
    path: '/currencies',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageCountriesRoute = AuthedAdminManageCountriesImport.update(
  {
    id: '/countries',
    path: '/countries',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any,
);

const AuthedAdminManageAddressesRoute = AuthedAdminManageAddressesImport.update(
  {
    id: '/addresses',
    path: '/addresses',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any,
);

const AuthedAdminDistroUploadRoute = AuthedAdminDistroUploadImport.update({
  id: '/distro/upload',
  path: '/distro/upload',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminDistroHistoryRoute = AuthedAdminDistroHistoryImport.update({
  id: '/distro/history',
  path: '/distro/history',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminContentProjectsRoute = AuthedAdminContentProjectsImport.update(
  {
    id: '/content/projects',
    path: '/content/projects',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any,
);

const AuthedAdminContentFileManagerRoute =
  AuthedAdminContentFileManagerImport.update({
    id: '/content/file-manager',
    path: '/content/file-manager',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminContentFaqRoute = AuthedAdminContentFaqImport.update({
  id: '/content/faq',
  path: '/content/faq',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminContentAnnouncementsRoute =
  AuthedAdminContentAnnouncementsImport.update({
    id: '/content/announcements',
    path: '/content/announcements',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminApprovalsOrdersRoute = AuthedAdminApprovalsOrdersImport.update(
  {
    id: '/approvals/orders',
    path: '/approvals/orders',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any,
);

const AuthedAdminApprovalsLocationRoute =
  AuthedAdminApprovalsLocationImport.update({
    id: '/approvals/location',
    path: '/approvals/location',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminReportsSupportRequestsEmailIdRoute =
  AuthedAdminReportsSupportRequestsEmailIdImport.update({
    id: '/reports/supportRequests_/$emailId',
    path: '/reports/supportRequests/$emailId',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminReportsLocationFeedLocationIdRoute =
  AuthedAdminReportsLocationFeedLocationIdImport.update({
    id: '/reports/location-feed_/$locationId',
    path: '/reports/location-feed/$locationId',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminReportsEmailsEmailIdRoute =
  AuthedAdminReportsEmailsEmailIdImport.update({
    id: '/reports/emails_/$emailId',
    path: '/reports/emails/$emailId',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminManageUsersAddRoute = AuthedAdminManageUsersAddImport.update({
  id: '/users_/add',
  path: '/users/add',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManageUsersUserNameRoute =
  AuthedAdminManageUsersUserNameImport.update({
    id: '/users_/$userName',
    path: '/users/$userName',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageSubprogramsAddRoute =
  AuthedAdminManageSubprogramsAddImport.update({
    id: '/subprograms_/add',
    path: '/subprograms/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageProgramsAddRoute =
  AuthedAdminManageProgramsAddImport.update({
    id: '/programs_/add',
    path: '/programs/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageProgramsProgramIdRoute =
  AuthedAdminManageProgramsProgramIdImport.update({
    id: '/programs_/$programId',
    path: '/programs/$programId',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageProductsAddRoute =
  AuthedAdminManageProductsAddImport.update({
    id: '/products_/add',
    path: '/products/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageProductsItemIdRoute =
  AuthedAdminManageProductsItemIdImport.update({
    id: '/products_/$itemId',
    path: '/products/$itemId',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageMerchandisersAddRoute =
  AuthedAdminManageMerchandisersAddImport.update({
    id: '/merchandisers_/add',
    path: '/merchandisers/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageMerchandisersLocationIdRoute =
  AuthedAdminManageMerchandisersLocationIdImport.update({
    id: '/merchandisers_/$locationId',
    path: '/merchandisers/$locationId',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageCustomersAddRoute =
  AuthedAdminManageCustomersAddImport.update({
    id: '/customers_/add',
    path: '/customers/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageCustomersCustomerCodeRoute =
  AuthedAdminManageCustomersCustomerCodeImport.update({
    id: '/customers_/$customerCode',
    path: '/customers/$customerCode',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageCountriesAddRoute =
  AuthedAdminManageCountriesAddImport.update({
    id: '/countries_/add',
    path: '/countries/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageCountriesCountryCodeRoute =
  AuthedAdminManageCountriesCountryCodeImport.update({
    id: '/countries_/$countryCode',
    path: '/countries/$countryCode',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageAddressesAddressBookIdRoute =
  AuthedAdminManageAddressesAddressBookIdImport.update({
    id: '/addresses_/$addressBookId',
    path: '/addresses/$addressBookId',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminContentFileManagerUploadRoute =
  AuthedAdminContentFileManagerUploadImport.update({
    id: '/content/file-manager_/upload',
    path: '/content/file-manager/upload',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminContentFileManagerDetailRoute =
  AuthedAdminContentFileManagerDetailImport.update({
    id: '/content/file-manager_/detail',
    path: '/content/file-manager/detail',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminContentFaqAddRoute = AuthedAdminContentFaqAddImport.update({
  id: '/content/faq_/add',
  path: '/content/faq/add',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminContentFaqFaqIdRoute = AuthedAdminContentFaqFaqIdImport.update(
  {
    id: '/content/faq_/$faqId',
    path: '/content/faq/$faqId',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any,
);

const AuthedAdminContentAnnouncementsAddRoute =
  AuthedAdminContentAnnouncementsAddImport.update({
    id: '/content/announcements_/add',
    path: '/content/announcements/add',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminContentAnnouncementsAnnouncementIdRoute =
  AuthedAdminContentAnnouncementsAnnouncementIdImport.update({
    id: '/content/announcements_/$announcementId',
    path: '/content/announcements/$announcementId',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminApprovalsOrdersPoNumberRoute =
  AuthedAdminApprovalsOrdersPoNumberImport.update({
    id: '/approvals/orders_/$poNumber',
    path: '/approvals/orders/$poNumber',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminApprovalsLocationUserIdRoute =
  AuthedAdminApprovalsLocationUserIdImport.update({
    id: '/approvals/location_/$userId',
    path: '/approvals/location/$userId',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminManageSubprogramsCustomerIdCountryCodeRoute =
  AuthedAdminManageSubprogramsCustomerIdCountryCodeImport.update({
    id: '/subprograms_/$customerId_/$countryCode',
    path: '/subprograms/$customerId/$countryCode',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authed': {
      id: '/_authed';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof AuthedRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/_authed/_admin': {
      id: '/_authed/_admin';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof AuthedAdminRouteImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/(errors)/not-found': {
      id: '/(errors)/not-found';
      path: '/not-found';
      fullPath: '/not-found';
      preLoaderRoute: typeof errorsNotFoundImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/first-login': {
      id: '/(public)/first-login';
      path: '/first-login';
      fullPath: '/first-login';
      preLoaderRoute: typeof publicFirstLoginImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/forgot-password': {
      id: '/(public)/forgot-password';
      path: '/forgot-password';
      fullPath: '/forgot-password';
      preLoaderRoute: typeof publicForgotPasswordImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/forgot-username': {
      id: '/(public)/forgot-username';
      path: '/forgot-username';
      fullPath: '/forgot-username';
      preLoaderRoute: typeof publicForgotUsernameImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/login': {
      id: '/(public)/login';
      path: '/login';
      fullPath: '/login';
      preLoaderRoute: typeof publicLoginImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/logout': {
      id: '/(public)/logout';
      path: '/logout';
      fullPath: '/logout';
      preLoaderRoute: typeof publicLogoutImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/privacy': {
      id: '/(public)/privacy';
      path: '/privacy';
      fullPath: '/privacy';
      preLoaderRoute: typeof publicPrivacyImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/reset-password': {
      id: '/(public)/reset-password';
      path: '/reset-password';
      fullPath: '/reset-password';
      preLoaderRoute: typeof publicResetPasswordImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/session-expired': {
      id: '/(public)/session-expired';
      path: '/session-expired';
      fullPath: '/session-expired';
      preLoaderRoute: typeof publicSessionExpiredImport;
      parentRoute: typeof rootRoute;
    };
    '/_authed/address-book': {
      id: '/_authed/address-book';
      path: '/address-book';
      fullPath: '/address-book';
      preLoaderRoute: typeof AuthedAddressBookImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/help': {
      id: '/_authed/help';
      path: '/help';
      fullPath: '/help';
      preLoaderRoute: typeof AuthedHelpImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/profile': {
      id: '/_authed/profile';
      path: '/profile';
      fullPath: '/profile';
      preLoaderRoute: typeof AuthedProfileImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/returns': {
      id: '/_authed/returns';
      path: '/returns';
      fullPath: '/returns';
      preLoaderRoute: typeof AuthedReturnsImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/': {
      id: '/_authed/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof AuthedIndexImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/(public)/first-login_/form': {
      id: '/(public)/first-login_/form';
      path: '/first-login/form';
      fullPath: '/first-login/form';
      preLoaderRoute: typeof publicFirstLoginFormImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/reset-password_/form': {
      id: '/(public)/reset-password_/form';
      path: '/reset-password/form';
      fullPath: '/reset-password/form';
      preLoaderRoute: typeof publicResetPasswordFormImport;
      parentRoute: typeof rootRoute;
    };
    '/_authed/_admin/manage': {
      id: '/_authed/_admin/manage';
      path: '/manage';
      fullPath: '/manage';
      preLoaderRoute: typeof AuthedAdminManageImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/cart/$step': {
      id: '/_authed/cart/$step';
      path: '/cart/$step';
      fullPath: '/cart/$step';
      preLoaderRoute: typeof AuthedCartStepImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/orders/$poNumber': {
      id: '/_authed/orders/$poNumber';
      path: '/orders/$poNumber';
      fullPath: '/orders/$poNumber';
      preLoaderRoute: typeof AuthedOrdersPoNumberImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/orders/search': {
      id: '/_authed/orders/search';
      path: '/orders/search';
      fullPath: '/orders/search';
      preLoaderRoute: typeof AuthedOrdersSearchImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/returns_/$orderNumber': {
      id: '/_authed/returns_/$orderNumber';
      path: '/returns/$orderNumber';
      fullPath: '/returns/$orderNumber';
      preLoaderRoute: typeof AuthedReturnsOrderNumberImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/cart/': {
      id: '/_authed/cart/';
      path: '/cart';
      fullPath: '/cart';
      preLoaderRoute: typeof AuthedCartIndexImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/_admin/approvals/location': {
      id: '/_authed/_admin/approvals/location';
      path: '/approvals/location';
      fullPath: '/approvals/location';
      preLoaderRoute: typeof AuthedAdminApprovalsLocationImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/approvals/orders': {
      id: '/_authed/_admin/approvals/orders';
      path: '/approvals/orders';
      fullPath: '/approvals/orders';
      preLoaderRoute: typeof AuthedAdminApprovalsOrdersImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/announcements': {
      id: '/_authed/_admin/content/announcements';
      path: '/content/announcements';
      fullPath: '/content/announcements';
      preLoaderRoute: typeof AuthedAdminContentAnnouncementsImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/faq': {
      id: '/_authed/_admin/content/faq';
      path: '/content/faq';
      fullPath: '/content/faq';
      preLoaderRoute: typeof AuthedAdminContentFaqImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/file-manager': {
      id: '/_authed/_admin/content/file-manager';
      path: '/content/file-manager';
      fullPath: '/content/file-manager';
      preLoaderRoute: typeof AuthedAdminContentFileManagerImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/projects': {
      id: '/_authed/_admin/content/projects';
      path: '/content/projects';
      fullPath: '/content/projects';
      preLoaderRoute: typeof AuthedAdminContentProjectsImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/distro/history': {
      id: '/_authed/_admin/distro/history';
      path: '/distro/history';
      fullPath: '/distro/history';
      preLoaderRoute: typeof AuthedAdminDistroHistoryImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/distro/upload': {
      id: '/_authed/_admin/distro/upload';
      path: '/distro/upload';
      fullPath: '/distro/upload';
      preLoaderRoute: typeof AuthedAdminDistroUploadImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/manage/addresses': {
      id: '/_authed/_admin/manage/addresses';
      path: '/addresses';
      fullPath: '/manage/addresses';
      preLoaderRoute: typeof AuthedAdminManageAddressesImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/countries': {
      id: '/_authed/_admin/manage/countries';
      path: '/countries';
      fullPath: '/manage/countries';
      preLoaderRoute: typeof AuthedAdminManageCountriesImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/currencies': {
      id: '/_authed/_admin/manage/currencies';
      path: '/currencies';
      fullPath: '/manage/currencies';
      preLoaderRoute: typeof AuthedAdminManageCurrenciesImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/customers': {
      id: '/_authed/_admin/manage/customers';
      path: '/customers';
      fullPath: '/manage/customers';
      preLoaderRoute: typeof AuthedAdminManageCustomersImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/line-of-business': {
      id: '/_authed/_admin/manage/line-of-business';
      path: '/line-of-business';
      fullPath: '/manage/line-of-business';
      preLoaderRoute: typeof AuthedAdminManageLineOfBusinessImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/locations': {
      id: '/_authed/_admin/manage/locations';
      path: '/locations';
      fullPath: '/manage/locations';
      preLoaderRoute: typeof AuthedAdminManageLocationsImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/merchandisers': {
      id: '/_authed/_admin/manage/merchandisers';
      path: '/merchandisers';
      fullPath: '/manage/merchandisers';
      preLoaderRoute: typeof AuthedAdminManageMerchandisersImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/plants': {
      id: '/_authed/_admin/manage/plants';
      path: '/plants';
      fullPath: '/manage/plants';
      preLoaderRoute: typeof AuthedAdminManagePlantsImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/products': {
      id: '/_authed/_admin/manage/products';
      path: '/products';
      fullPath: '/manage/products';
      preLoaderRoute: typeof AuthedAdminManageProductsImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/programs': {
      id: '/_authed/_admin/manage/programs';
      path: '/programs';
      fullPath: '/manage/programs';
      preLoaderRoute: typeof AuthedAdminManageProgramsImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/subprograms': {
      id: '/_authed/_admin/manage/subprograms';
      path: '/subprograms';
      fullPath: '/manage/subprograms';
      preLoaderRoute: typeof AuthedAdminManageSubprogramsImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/users': {
      id: '/_authed/_admin/manage/users';
      path: '/users';
      fullPath: '/manage/users';
      preLoaderRoute: typeof AuthedAdminManageUsersImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/reports/emails': {
      id: '/_authed/_admin/reports/emails';
      path: '/reports/emails';
      fullPath: '/reports/emails';
      preLoaderRoute: typeof AuthedAdminReportsEmailsImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/reports/location-feed': {
      id: '/_authed/_admin/reports/location-feed';
      path: '/reports/location-feed';
      fullPath: '/reports/location-feed';
      preLoaderRoute: typeof AuthedAdminReportsLocationFeedImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/reports/products': {
      id: '/_authed/_admin/reports/products';
      path: '/reports/products';
      fullPath: '/reports/products';
      preLoaderRoute: typeof AuthedAdminReportsProductsImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/reports/supportRequests': {
      id: '/_authed/_admin/reports/supportRequests';
      path: '/reports/supportRequests';
      fullPath: '/reports/supportRequests';
      preLoaderRoute: typeof AuthedAdminReportsSupportRequestsImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/reports/users': {
      id: '/_authed/_admin/reports/users';
      path: '/reports/users';
      fullPath: '/reports/users';
      preLoaderRoute: typeof AuthedAdminReportsUsersImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/cart/$cartId/confirmation': {
      id: '/_authed/cart/$cartId/confirmation';
      path: '/cart/$cartId/confirmation';
      fullPath: '/cart/$cartId/confirmation';
      preLoaderRoute: typeof AuthedCartCartIdConfirmationImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/_admin/approvals/location_/$userId': {
      id: '/_authed/_admin/approvals/location_/$userId';
      path: '/approvals/location/$userId';
      fullPath: '/approvals/location/$userId';
      preLoaderRoute: typeof AuthedAdminApprovalsLocationUserIdImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/approvals/orders_/$poNumber': {
      id: '/_authed/_admin/approvals/orders_/$poNumber';
      path: '/approvals/orders/$poNumber';
      fullPath: '/approvals/orders/$poNumber';
      preLoaderRoute: typeof AuthedAdminApprovalsOrdersPoNumberImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/announcements_/$announcementId': {
      id: '/_authed/_admin/content/announcements_/$announcementId';
      path: '/content/announcements/$announcementId';
      fullPath: '/content/announcements/$announcementId';
      preLoaderRoute: typeof AuthedAdminContentAnnouncementsAnnouncementIdImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/announcements_/add': {
      id: '/_authed/_admin/content/announcements_/add';
      path: '/content/announcements/add';
      fullPath: '/content/announcements/add';
      preLoaderRoute: typeof AuthedAdminContentAnnouncementsAddImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/faq_/$faqId': {
      id: '/_authed/_admin/content/faq_/$faqId';
      path: '/content/faq/$faqId';
      fullPath: '/content/faq/$faqId';
      preLoaderRoute: typeof AuthedAdminContentFaqFaqIdImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/faq_/add': {
      id: '/_authed/_admin/content/faq_/add';
      path: '/content/faq/add';
      fullPath: '/content/faq/add';
      preLoaderRoute: typeof AuthedAdminContentFaqAddImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/file-manager_/detail': {
      id: '/_authed/_admin/content/file-manager_/detail';
      path: '/content/file-manager/detail';
      fullPath: '/content/file-manager/detail';
      preLoaderRoute: typeof AuthedAdminContentFileManagerDetailImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/file-manager_/upload': {
      id: '/_authed/_admin/content/file-manager_/upload';
      path: '/content/file-manager/upload';
      fullPath: '/content/file-manager/upload';
      preLoaderRoute: typeof AuthedAdminContentFileManagerUploadImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/manage/addresses_/$addressBookId': {
      id: '/_authed/_admin/manage/addresses_/$addressBookId';
      path: '/addresses/$addressBookId';
      fullPath: '/manage/addresses/$addressBookId';
      preLoaderRoute: typeof AuthedAdminManageAddressesAddressBookIdImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/countries_/$countryCode': {
      id: '/_authed/_admin/manage/countries_/$countryCode';
      path: '/countries/$countryCode';
      fullPath: '/manage/countries/$countryCode';
      preLoaderRoute: typeof AuthedAdminManageCountriesCountryCodeImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/countries_/add': {
      id: '/_authed/_admin/manage/countries_/add';
      path: '/countries/add';
      fullPath: '/manage/countries/add';
      preLoaderRoute: typeof AuthedAdminManageCountriesAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/customers_/$customerCode': {
      id: '/_authed/_admin/manage/customers_/$customerCode';
      path: '/customers/$customerCode';
      fullPath: '/manage/customers/$customerCode';
      preLoaderRoute: typeof AuthedAdminManageCustomersCustomerCodeImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/customers_/add': {
      id: '/_authed/_admin/manage/customers_/add';
      path: '/customers/add';
      fullPath: '/manage/customers/add';
      preLoaderRoute: typeof AuthedAdminManageCustomersAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/merchandisers_/$locationId': {
      id: '/_authed/_admin/manage/merchandisers_/$locationId';
      path: '/merchandisers/$locationId';
      fullPath: '/manage/merchandisers/$locationId';
      preLoaderRoute: typeof AuthedAdminManageMerchandisersLocationIdImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/merchandisers_/add': {
      id: '/_authed/_admin/manage/merchandisers_/add';
      path: '/merchandisers/add';
      fullPath: '/manage/merchandisers/add';
      preLoaderRoute: typeof AuthedAdminManageMerchandisersAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/products_/$itemId': {
      id: '/_authed/_admin/manage/products_/$itemId';
      path: '/products/$itemId';
      fullPath: '/manage/products/$itemId';
      preLoaderRoute: typeof AuthedAdminManageProductsItemIdImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/products_/add': {
      id: '/_authed/_admin/manage/products_/add';
      path: '/products/add';
      fullPath: '/manage/products/add';
      preLoaderRoute: typeof AuthedAdminManageProductsAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/programs_/$programId': {
      id: '/_authed/_admin/manage/programs_/$programId';
      path: '/programs/$programId';
      fullPath: '/manage/programs/$programId';
      preLoaderRoute: typeof AuthedAdminManageProgramsProgramIdImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/programs_/add': {
      id: '/_authed/_admin/manage/programs_/add';
      path: '/programs/add';
      fullPath: '/manage/programs/add';
      preLoaderRoute: typeof AuthedAdminManageProgramsAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/subprograms_/add': {
      id: '/_authed/_admin/manage/subprograms_/add';
      path: '/subprograms/add';
      fullPath: '/manage/subprograms/add';
      preLoaderRoute: typeof AuthedAdminManageSubprogramsAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/users_/$userName': {
      id: '/_authed/_admin/manage/users_/$userName';
      path: '/users/$userName';
      fullPath: '/manage/users/$userName';
      preLoaderRoute: typeof AuthedAdminManageUsersUserNameImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/users_/add': {
      id: '/_authed/_admin/manage/users_/add';
      path: '/users/add';
      fullPath: '/manage/users/add';
      preLoaderRoute: typeof AuthedAdminManageUsersAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/reports/emails_/$emailId': {
      id: '/_authed/_admin/reports/emails_/$emailId';
      path: '/reports/emails/$emailId';
      fullPath: '/reports/emails/$emailId';
      preLoaderRoute: typeof AuthedAdminReportsEmailsEmailIdImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/reports/location-feed_/$locationId': {
      id: '/_authed/_admin/reports/location-feed_/$locationId';
      path: '/reports/location-feed/$locationId';
      fullPath: '/reports/location-feed/$locationId';
      preLoaderRoute: typeof AuthedAdminReportsLocationFeedLocationIdImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/reports/supportRequests_/$emailId': {
      id: '/_authed/_admin/reports/supportRequests_/$emailId';
      path: '/reports/supportRequests/$emailId';
      fullPath: '/reports/supportRequests/$emailId';
      preLoaderRoute: typeof AuthedAdminReportsSupportRequestsEmailIdImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/manage/subprograms_/$customerId_/$countryCode': {
      id: '/_authed/_admin/manage/subprograms_/$customerId_/$countryCode';
      path: '/subprograms/$customerId/$countryCode';
      fullPath: '/manage/subprograms/$customerId/$countryCode';
      preLoaderRoute: typeof AuthedAdminManageSubprogramsCustomerIdCountryCodeImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
  }
}

// Create and export the route tree

interface AuthedAdminManageRouteChildren {
  AuthedAdminManageAddressesRoute: typeof AuthedAdminManageAddressesRoute;
  AuthedAdminManageCountriesRoute: typeof AuthedAdminManageCountriesRoute;
  AuthedAdminManageCurrenciesRoute: typeof AuthedAdminManageCurrenciesRoute;
  AuthedAdminManageCustomersRoute: typeof AuthedAdminManageCustomersRoute;
  AuthedAdminManageLineOfBusinessRoute: typeof AuthedAdminManageLineOfBusinessRoute;
  AuthedAdminManageLocationsRoute: typeof AuthedAdminManageLocationsRoute;
  AuthedAdminManageMerchandisersRoute: typeof AuthedAdminManageMerchandisersRoute;
  AuthedAdminManagePlantsRoute: typeof AuthedAdminManagePlantsRoute;
  AuthedAdminManageProductsRoute: typeof AuthedAdminManageProductsRoute;
  AuthedAdminManageProgramsRoute: typeof AuthedAdminManageProgramsRoute;
  AuthedAdminManageSubprogramsRoute: typeof AuthedAdminManageSubprogramsRoute;
  AuthedAdminManageUsersRoute: typeof AuthedAdminManageUsersRoute;
  AuthedAdminManageAddressesAddressBookIdRoute: typeof AuthedAdminManageAddressesAddressBookIdRoute;
  AuthedAdminManageCountriesCountryCodeRoute: typeof AuthedAdminManageCountriesCountryCodeRoute;
  AuthedAdminManageCountriesAddRoute: typeof AuthedAdminManageCountriesAddRoute;
  AuthedAdminManageCustomersCustomerCodeRoute: typeof AuthedAdminManageCustomersCustomerCodeRoute;
  AuthedAdminManageCustomersAddRoute: typeof AuthedAdminManageCustomersAddRoute;
  AuthedAdminManageMerchandisersLocationIdRoute: typeof AuthedAdminManageMerchandisersLocationIdRoute;
  AuthedAdminManageMerchandisersAddRoute: typeof AuthedAdminManageMerchandisersAddRoute;
  AuthedAdminManageProductsItemIdRoute: typeof AuthedAdminManageProductsItemIdRoute;
  AuthedAdminManageProductsAddRoute: typeof AuthedAdminManageProductsAddRoute;
  AuthedAdminManageProgramsProgramIdRoute: typeof AuthedAdminManageProgramsProgramIdRoute;
  AuthedAdminManageProgramsAddRoute: typeof AuthedAdminManageProgramsAddRoute;
  AuthedAdminManageSubprogramsAddRoute: typeof AuthedAdminManageSubprogramsAddRoute;
  AuthedAdminManageUsersUserNameRoute: typeof AuthedAdminManageUsersUserNameRoute;
  AuthedAdminManageUsersAddRoute: typeof AuthedAdminManageUsersAddRoute;
  AuthedAdminManageSubprogramsCustomerIdCountryCodeRoute: typeof AuthedAdminManageSubprogramsCustomerIdCountryCodeRoute;
}

const AuthedAdminManageRouteChildren: AuthedAdminManageRouteChildren = {
  AuthedAdminManageAddressesRoute: AuthedAdminManageAddressesRoute,
  AuthedAdminManageCountriesRoute: AuthedAdminManageCountriesRoute,
  AuthedAdminManageCurrenciesRoute: AuthedAdminManageCurrenciesRoute,
  AuthedAdminManageCustomersRoute: AuthedAdminManageCustomersRoute,
  AuthedAdminManageLineOfBusinessRoute: AuthedAdminManageLineOfBusinessRoute,
  AuthedAdminManageLocationsRoute: AuthedAdminManageLocationsRoute,
  AuthedAdminManageMerchandisersRoute: AuthedAdminManageMerchandisersRoute,
  AuthedAdminManagePlantsRoute: AuthedAdminManagePlantsRoute,
  AuthedAdminManageProductsRoute: AuthedAdminManageProductsRoute,
  AuthedAdminManageProgramsRoute: AuthedAdminManageProgramsRoute,
  AuthedAdminManageSubprogramsRoute: AuthedAdminManageSubprogramsRoute,
  AuthedAdminManageUsersRoute: AuthedAdminManageUsersRoute,
  AuthedAdminManageAddressesAddressBookIdRoute:
    AuthedAdminManageAddressesAddressBookIdRoute,
  AuthedAdminManageCountriesCountryCodeRoute:
    AuthedAdminManageCountriesCountryCodeRoute,
  AuthedAdminManageCountriesAddRoute: AuthedAdminManageCountriesAddRoute,
  AuthedAdminManageCustomersCustomerCodeRoute:
    AuthedAdminManageCustomersCustomerCodeRoute,
  AuthedAdminManageCustomersAddRoute: AuthedAdminManageCustomersAddRoute,
  AuthedAdminManageMerchandisersLocationIdRoute:
    AuthedAdminManageMerchandisersLocationIdRoute,
  AuthedAdminManageMerchandisersAddRoute:
    AuthedAdminManageMerchandisersAddRoute,
  AuthedAdminManageProductsItemIdRoute: AuthedAdminManageProductsItemIdRoute,
  AuthedAdminManageProductsAddRoute: AuthedAdminManageProductsAddRoute,
  AuthedAdminManageProgramsProgramIdRoute:
    AuthedAdminManageProgramsProgramIdRoute,
  AuthedAdminManageProgramsAddRoute: AuthedAdminManageProgramsAddRoute,
  AuthedAdminManageSubprogramsAddRoute: AuthedAdminManageSubprogramsAddRoute,
  AuthedAdminManageUsersUserNameRoute: AuthedAdminManageUsersUserNameRoute,
  AuthedAdminManageUsersAddRoute: AuthedAdminManageUsersAddRoute,
  AuthedAdminManageSubprogramsCustomerIdCountryCodeRoute:
    AuthedAdminManageSubprogramsCustomerIdCountryCodeRoute,
};

const AuthedAdminManageRouteWithChildren =
  AuthedAdminManageRoute._addFileChildren(AuthedAdminManageRouteChildren);

interface AuthedAdminRouteRouteChildren {
  AuthedAdminManageRoute: typeof AuthedAdminManageRouteWithChildren;
  AuthedAdminApprovalsLocationRoute: typeof AuthedAdminApprovalsLocationRoute;
  AuthedAdminApprovalsOrdersRoute: typeof AuthedAdminApprovalsOrdersRoute;
  AuthedAdminContentAnnouncementsRoute: typeof AuthedAdminContentAnnouncementsRoute;
  AuthedAdminContentFaqRoute: typeof AuthedAdminContentFaqRoute;
  AuthedAdminContentFileManagerRoute: typeof AuthedAdminContentFileManagerRoute;
  AuthedAdminContentProjectsRoute: typeof AuthedAdminContentProjectsRoute;
  AuthedAdminDistroHistoryRoute: typeof AuthedAdminDistroHistoryRoute;
  AuthedAdminDistroUploadRoute: typeof AuthedAdminDistroUploadRoute;
  AuthedAdminReportsEmailsRoute: typeof AuthedAdminReportsEmailsRoute;
  AuthedAdminReportsLocationFeedRoute: typeof AuthedAdminReportsLocationFeedRoute;
  AuthedAdminReportsProductsRoute: typeof AuthedAdminReportsProductsRoute;
  AuthedAdminReportsSupportRequestsRoute: typeof AuthedAdminReportsSupportRequestsRoute;
  AuthedAdminReportsUsersRoute: typeof AuthedAdminReportsUsersRoute;
  AuthedAdminApprovalsLocationUserIdRoute: typeof AuthedAdminApprovalsLocationUserIdRoute;
  AuthedAdminApprovalsOrdersPoNumberRoute: typeof AuthedAdminApprovalsOrdersPoNumberRoute;
  AuthedAdminContentAnnouncementsAnnouncementIdRoute: typeof AuthedAdminContentAnnouncementsAnnouncementIdRoute;
  AuthedAdminContentAnnouncementsAddRoute: typeof AuthedAdminContentAnnouncementsAddRoute;
  AuthedAdminContentFaqFaqIdRoute: typeof AuthedAdminContentFaqFaqIdRoute;
  AuthedAdminContentFaqAddRoute: typeof AuthedAdminContentFaqAddRoute;
  AuthedAdminContentFileManagerDetailRoute: typeof AuthedAdminContentFileManagerDetailRoute;
  AuthedAdminContentFileManagerUploadRoute: typeof AuthedAdminContentFileManagerUploadRoute;
  AuthedAdminReportsEmailsEmailIdRoute: typeof AuthedAdminReportsEmailsEmailIdRoute;
  AuthedAdminReportsLocationFeedLocationIdRoute: typeof AuthedAdminReportsLocationFeedLocationIdRoute;
  AuthedAdminReportsSupportRequestsEmailIdRoute: typeof AuthedAdminReportsSupportRequestsEmailIdRoute;
}

const AuthedAdminRouteRouteChildren: AuthedAdminRouteRouteChildren = {
  AuthedAdminManageRoute: AuthedAdminManageRouteWithChildren,
  AuthedAdminApprovalsLocationRoute: AuthedAdminApprovalsLocationRoute,
  AuthedAdminApprovalsOrdersRoute: AuthedAdminApprovalsOrdersRoute,
  AuthedAdminContentAnnouncementsRoute: AuthedAdminContentAnnouncementsRoute,
  AuthedAdminContentFaqRoute: AuthedAdminContentFaqRoute,
  AuthedAdminContentFileManagerRoute: AuthedAdminContentFileManagerRoute,
  AuthedAdminContentProjectsRoute: AuthedAdminContentProjectsRoute,
  AuthedAdminDistroHistoryRoute: AuthedAdminDistroHistoryRoute,
  AuthedAdminDistroUploadRoute: AuthedAdminDistroUploadRoute,
  AuthedAdminReportsEmailsRoute: AuthedAdminReportsEmailsRoute,
  AuthedAdminReportsLocationFeedRoute: AuthedAdminReportsLocationFeedRoute,
  AuthedAdminReportsProductsRoute: AuthedAdminReportsProductsRoute,
  AuthedAdminReportsSupportRequestsRoute:
    AuthedAdminReportsSupportRequestsRoute,
  AuthedAdminReportsUsersRoute: AuthedAdminReportsUsersRoute,
  AuthedAdminApprovalsLocationUserIdRoute:
    AuthedAdminApprovalsLocationUserIdRoute,
  AuthedAdminApprovalsOrdersPoNumberRoute:
    AuthedAdminApprovalsOrdersPoNumberRoute,
  AuthedAdminContentAnnouncementsAnnouncementIdRoute:
    AuthedAdminContentAnnouncementsAnnouncementIdRoute,
  AuthedAdminContentAnnouncementsAddRoute:
    AuthedAdminContentAnnouncementsAddRoute,
  AuthedAdminContentFaqFaqIdRoute: AuthedAdminContentFaqFaqIdRoute,
  AuthedAdminContentFaqAddRoute: AuthedAdminContentFaqAddRoute,
  AuthedAdminContentFileManagerDetailRoute:
    AuthedAdminContentFileManagerDetailRoute,
  AuthedAdminContentFileManagerUploadRoute:
    AuthedAdminContentFileManagerUploadRoute,
  AuthedAdminReportsEmailsEmailIdRoute: AuthedAdminReportsEmailsEmailIdRoute,
  AuthedAdminReportsLocationFeedLocationIdRoute:
    AuthedAdminReportsLocationFeedLocationIdRoute,
  AuthedAdminReportsSupportRequestsEmailIdRoute:
    AuthedAdminReportsSupportRequestsEmailIdRoute,
};

const AuthedAdminRouteRouteWithChildren =
  AuthedAdminRouteRoute._addFileChildren(AuthedAdminRouteRouteChildren);

interface AuthedRouteRouteChildren {
  AuthedAdminRouteRoute: typeof AuthedAdminRouteRouteWithChildren;
  AuthedAddressBookRoute: typeof AuthedAddressBookRoute;
  AuthedHelpRoute: typeof AuthedHelpRoute;
  AuthedProfileRoute: typeof AuthedProfileRoute;
  AuthedReturnsRoute: typeof AuthedReturnsRoute;
  AuthedIndexRoute: typeof AuthedIndexRoute;
  AuthedCartStepRoute: typeof AuthedCartStepRoute;
  AuthedOrdersPoNumberRoute: typeof AuthedOrdersPoNumberRoute;
  AuthedOrdersSearchRoute: typeof AuthedOrdersSearchRoute;
  AuthedReturnsOrderNumberRoute: typeof AuthedReturnsOrderNumberRoute;
  AuthedCartIndexRoute: typeof AuthedCartIndexRoute;
  AuthedCartCartIdConfirmationRoute: typeof AuthedCartCartIdConfirmationRoute;
}

const AuthedRouteRouteChildren: AuthedRouteRouteChildren = {
  AuthedAdminRouteRoute: AuthedAdminRouteRouteWithChildren,
  AuthedAddressBookRoute: AuthedAddressBookRoute,
  AuthedHelpRoute: AuthedHelpRoute,
  AuthedProfileRoute: AuthedProfileRoute,
  AuthedReturnsRoute: AuthedReturnsRoute,
  AuthedIndexRoute: AuthedIndexRoute,
  AuthedCartStepRoute: AuthedCartStepRoute,
  AuthedOrdersPoNumberRoute: AuthedOrdersPoNumberRoute,
  AuthedOrdersSearchRoute: AuthedOrdersSearchRoute,
  AuthedReturnsOrderNumberRoute: AuthedReturnsOrderNumberRoute,
  AuthedCartIndexRoute: AuthedCartIndexRoute,
  AuthedCartCartIdConfirmationRoute: AuthedCartCartIdConfirmationRoute,
};

const AuthedRouteRouteWithChildren = AuthedRouteRoute._addFileChildren(
  AuthedRouteRouteChildren,
);

export interface FileRoutesByFullPath {
  '': typeof AuthedAdminRouteRouteWithChildren;
  '/not-found': typeof errorsNotFoundRoute;
  '/first-login': typeof publicFirstLoginRoute;
  '/forgot-password': typeof publicForgotPasswordRoute;
  '/forgot-username': typeof publicForgotUsernameRoute;
  '/login': typeof publicLoginRoute;
  '/logout': typeof publicLogoutRoute;
  '/privacy': typeof publicPrivacyRoute;
  '/reset-password': typeof publicResetPasswordRoute;
  '/session-expired': typeof publicSessionExpiredRoute;
  '/address-book': typeof AuthedAddressBookRoute;
  '/help': typeof AuthedHelpRoute;
  '/profile': typeof AuthedProfileRoute;
  '/returns': typeof AuthedReturnsRoute;
  '/': typeof AuthedIndexRoute;
  '/first-login/form': typeof publicFirstLoginFormRoute;
  '/reset-password/form': typeof publicResetPasswordFormRoute;
  '/manage': typeof AuthedAdminManageRouteWithChildren;
  '/cart/$step': typeof AuthedCartStepRoute;
  '/orders/$poNumber': typeof AuthedOrdersPoNumberRoute;
  '/orders/search': typeof AuthedOrdersSearchRoute;
  '/returns/$orderNumber': typeof AuthedReturnsOrderNumberRoute;
  '/cart': typeof AuthedCartIndexRoute;
  '/approvals/location': typeof AuthedAdminApprovalsLocationRoute;
  '/approvals/orders': typeof AuthedAdminApprovalsOrdersRoute;
  '/content/announcements': typeof AuthedAdminContentAnnouncementsRoute;
  '/content/faq': typeof AuthedAdminContentFaqRoute;
  '/content/file-manager': typeof AuthedAdminContentFileManagerRoute;
  '/content/projects': typeof AuthedAdminContentProjectsRoute;
  '/distro/history': typeof AuthedAdminDistroHistoryRoute;
  '/distro/upload': typeof AuthedAdminDistroUploadRoute;
  '/manage/addresses': typeof AuthedAdminManageAddressesRoute;
  '/manage/countries': typeof AuthedAdminManageCountriesRoute;
  '/manage/currencies': typeof AuthedAdminManageCurrenciesRoute;
  '/manage/customers': typeof AuthedAdminManageCustomersRoute;
  '/manage/line-of-business': typeof AuthedAdminManageLineOfBusinessRoute;
  '/manage/locations': typeof AuthedAdminManageLocationsRoute;
  '/manage/merchandisers': typeof AuthedAdminManageMerchandisersRoute;
  '/manage/plants': typeof AuthedAdminManagePlantsRoute;
  '/manage/products': typeof AuthedAdminManageProductsRoute;
  '/manage/programs': typeof AuthedAdminManageProgramsRoute;
  '/manage/subprograms': typeof AuthedAdminManageSubprogramsRoute;
  '/manage/users': typeof AuthedAdminManageUsersRoute;
  '/reports/emails': typeof AuthedAdminReportsEmailsRoute;
  '/reports/location-feed': typeof AuthedAdminReportsLocationFeedRoute;
  '/reports/products': typeof AuthedAdminReportsProductsRoute;
  '/reports/supportRequests': typeof AuthedAdminReportsSupportRequestsRoute;
  '/reports/users': typeof AuthedAdminReportsUsersRoute;
  '/cart/$cartId/confirmation': typeof AuthedCartCartIdConfirmationRoute;
  '/approvals/location/$userId': typeof AuthedAdminApprovalsLocationUserIdRoute;
  '/approvals/orders/$poNumber': typeof AuthedAdminApprovalsOrdersPoNumberRoute;
  '/content/announcements/$announcementId': typeof AuthedAdminContentAnnouncementsAnnouncementIdRoute;
  '/content/announcements/add': typeof AuthedAdminContentAnnouncementsAddRoute;
  '/content/faq/$faqId': typeof AuthedAdminContentFaqFaqIdRoute;
  '/content/faq/add': typeof AuthedAdminContentFaqAddRoute;
  '/content/file-manager/detail': typeof AuthedAdminContentFileManagerDetailRoute;
  '/content/file-manager/upload': typeof AuthedAdminContentFileManagerUploadRoute;
  '/manage/addresses/$addressBookId': typeof AuthedAdminManageAddressesAddressBookIdRoute;
  '/manage/countries/$countryCode': typeof AuthedAdminManageCountriesCountryCodeRoute;
  '/manage/countries/add': typeof AuthedAdminManageCountriesAddRoute;
  '/manage/customers/$customerCode': typeof AuthedAdminManageCustomersCustomerCodeRoute;
  '/manage/customers/add': typeof AuthedAdminManageCustomersAddRoute;
  '/manage/merchandisers/$locationId': typeof AuthedAdminManageMerchandisersLocationIdRoute;
  '/manage/merchandisers/add': typeof AuthedAdminManageMerchandisersAddRoute;
  '/manage/products/$itemId': typeof AuthedAdminManageProductsItemIdRoute;
  '/manage/products/add': typeof AuthedAdminManageProductsAddRoute;
  '/manage/programs/$programId': typeof AuthedAdminManageProgramsProgramIdRoute;
  '/manage/programs/add': typeof AuthedAdminManageProgramsAddRoute;
  '/manage/subprograms/add': typeof AuthedAdminManageSubprogramsAddRoute;
  '/manage/users/$userName': typeof AuthedAdminManageUsersUserNameRoute;
  '/manage/users/add': typeof AuthedAdminManageUsersAddRoute;
  '/reports/emails/$emailId': typeof AuthedAdminReportsEmailsEmailIdRoute;
  '/reports/location-feed/$locationId': typeof AuthedAdminReportsLocationFeedLocationIdRoute;
  '/reports/supportRequests/$emailId': typeof AuthedAdminReportsSupportRequestsEmailIdRoute;
  '/manage/subprograms/$customerId/$countryCode': typeof AuthedAdminManageSubprogramsCustomerIdCountryCodeRoute;
}

export interface FileRoutesByTo {
  '': typeof AuthedAdminRouteRouteWithChildren;
  '/not-found': typeof errorsNotFoundRoute;
  '/first-login': typeof publicFirstLoginRoute;
  '/forgot-password': typeof publicForgotPasswordRoute;
  '/forgot-username': typeof publicForgotUsernameRoute;
  '/login': typeof publicLoginRoute;
  '/logout': typeof publicLogoutRoute;
  '/privacy': typeof publicPrivacyRoute;
  '/reset-password': typeof publicResetPasswordRoute;
  '/session-expired': typeof publicSessionExpiredRoute;
  '/address-book': typeof AuthedAddressBookRoute;
  '/help': typeof AuthedHelpRoute;
  '/profile': typeof AuthedProfileRoute;
  '/returns': typeof AuthedReturnsRoute;
  '/': typeof AuthedIndexRoute;
  '/first-login/form': typeof publicFirstLoginFormRoute;
  '/reset-password/form': typeof publicResetPasswordFormRoute;
  '/manage': typeof AuthedAdminManageRouteWithChildren;
  '/cart/$step': typeof AuthedCartStepRoute;
  '/orders/$poNumber': typeof AuthedOrdersPoNumberRoute;
  '/orders/search': typeof AuthedOrdersSearchRoute;
  '/returns/$orderNumber': typeof AuthedReturnsOrderNumberRoute;
  '/cart': typeof AuthedCartIndexRoute;
  '/approvals/location': typeof AuthedAdminApprovalsLocationRoute;
  '/approvals/orders': typeof AuthedAdminApprovalsOrdersRoute;
  '/content/announcements': typeof AuthedAdminContentAnnouncementsRoute;
  '/content/faq': typeof AuthedAdminContentFaqRoute;
  '/content/file-manager': typeof AuthedAdminContentFileManagerRoute;
  '/content/projects': typeof AuthedAdminContentProjectsRoute;
  '/distro/history': typeof AuthedAdminDistroHistoryRoute;
  '/distro/upload': typeof AuthedAdminDistroUploadRoute;
  '/manage/addresses': typeof AuthedAdminManageAddressesRoute;
  '/manage/countries': typeof AuthedAdminManageCountriesRoute;
  '/manage/currencies': typeof AuthedAdminManageCurrenciesRoute;
  '/manage/customers': typeof AuthedAdminManageCustomersRoute;
  '/manage/line-of-business': typeof AuthedAdminManageLineOfBusinessRoute;
  '/manage/locations': typeof AuthedAdminManageLocationsRoute;
  '/manage/merchandisers': typeof AuthedAdminManageMerchandisersRoute;
  '/manage/plants': typeof AuthedAdminManagePlantsRoute;
  '/manage/products': typeof AuthedAdminManageProductsRoute;
  '/manage/programs': typeof AuthedAdminManageProgramsRoute;
  '/manage/subprograms': typeof AuthedAdminManageSubprogramsRoute;
  '/manage/users': typeof AuthedAdminManageUsersRoute;
  '/reports/emails': typeof AuthedAdminReportsEmailsRoute;
  '/reports/location-feed': typeof AuthedAdminReportsLocationFeedRoute;
  '/reports/products': typeof AuthedAdminReportsProductsRoute;
  '/reports/supportRequests': typeof AuthedAdminReportsSupportRequestsRoute;
  '/reports/users': typeof AuthedAdminReportsUsersRoute;
  '/cart/$cartId/confirmation': typeof AuthedCartCartIdConfirmationRoute;
  '/approvals/location/$userId': typeof AuthedAdminApprovalsLocationUserIdRoute;
  '/approvals/orders/$poNumber': typeof AuthedAdminApprovalsOrdersPoNumberRoute;
  '/content/announcements/$announcementId': typeof AuthedAdminContentAnnouncementsAnnouncementIdRoute;
  '/content/announcements/add': typeof AuthedAdminContentAnnouncementsAddRoute;
  '/content/faq/$faqId': typeof AuthedAdminContentFaqFaqIdRoute;
  '/content/faq/add': typeof AuthedAdminContentFaqAddRoute;
  '/content/file-manager/detail': typeof AuthedAdminContentFileManagerDetailRoute;
  '/content/file-manager/upload': typeof AuthedAdminContentFileManagerUploadRoute;
  '/manage/addresses/$addressBookId': typeof AuthedAdminManageAddressesAddressBookIdRoute;
  '/manage/countries/$countryCode': typeof AuthedAdminManageCountriesCountryCodeRoute;
  '/manage/countries/add': typeof AuthedAdminManageCountriesAddRoute;
  '/manage/customers/$customerCode': typeof AuthedAdminManageCustomersCustomerCodeRoute;
  '/manage/customers/add': typeof AuthedAdminManageCustomersAddRoute;
  '/manage/merchandisers/$locationId': typeof AuthedAdminManageMerchandisersLocationIdRoute;
  '/manage/merchandisers/add': typeof AuthedAdminManageMerchandisersAddRoute;
  '/manage/products/$itemId': typeof AuthedAdminManageProductsItemIdRoute;
  '/manage/products/add': typeof AuthedAdminManageProductsAddRoute;
  '/manage/programs/$programId': typeof AuthedAdminManageProgramsProgramIdRoute;
  '/manage/programs/add': typeof AuthedAdminManageProgramsAddRoute;
  '/manage/subprograms/add': typeof AuthedAdminManageSubprogramsAddRoute;
  '/manage/users/$userName': typeof AuthedAdminManageUsersUserNameRoute;
  '/manage/users/add': typeof AuthedAdminManageUsersAddRoute;
  '/reports/emails/$emailId': typeof AuthedAdminReportsEmailsEmailIdRoute;
  '/reports/location-feed/$locationId': typeof AuthedAdminReportsLocationFeedLocationIdRoute;
  '/reports/supportRequests/$emailId': typeof AuthedAdminReportsSupportRequestsEmailIdRoute;
  '/manage/subprograms/$customerId/$countryCode': typeof AuthedAdminManageSubprogramsCustomerIdCountryCodeRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/_authed': typeof AuthedRouteRouteWithChildren;
  '/_authed/_admin': typeof AuthedAdminRouteRouteWithChildren;
  '/(errors)/not-found': typeof errorsNotFoundRoute;
  '/(public)/first-login': typeof publicFirstLoginRoute;
  '/(public)/forgot-password': typeof publicForgotPasswordRoute;
  '/(public)/forgot-username': typeof publicForgotUsernameRoute;
  '/(public)/login': typeof publicLoginRoute;
  '/(public)/logout': typeof publicLogoutRoute;
  '/(public)/privacy': typeof publicPrivacyRoute;
  '/(public)/reset-password': typeof publicResetPasswordRoute;
  '/(public)/session-expired': typeof publicSessionExpiredRoute;
  '/_authed/address-book': typeof AuthedAddressBookRoute;
  '/_authed/help': typeof AuthedHelpRoute;
  '/_authed/profile': typeof AuthedProfileRoute;
  '/_authed/returns': typeof AuthedReturnsRoute;
  '/_authed/': typeof AuthedIndexRoute;
  '/(public)/first-login_/form': typeof publicFirstLoginFormRoute;
  '/(public)/reset-password_/form': typeof publicResetPasswordFormRoute;
  '/_authed/_admin/manage': typeof AuthedAdminManageRouteWithChildren;
  '/_authed/cart/$step': typeof AuthedCartStepRoute;
  '/_authed/orders/$poNumber': typeof AuthedOrdersPoNumberRoute;
  '/_authed/orders/search': typeof AuthedOrdersSearchRoute;
  '/_authed/returns_/$orderNumber': typeof AuthedReturnsOrderNumberRoute;
  '/_authed/cart/': typeof AuthedCartIndexRoute;
  '/_authed/_admin/approvals/location': typeof AuthedAdminApprovalsLocationRoute;
  '/_authed/_admin/approvals/orders': typeof AuthedAdminApprovalsOrdersRoute;
  '/_authed/_admin/content/announcements': typeof AuthedAdminContentAnnouncementsRoute;
  '/_authed/_admin/content/faq': typeof AuthedAdminContentFaqRoute;
  '/_authed/_admin/content/file-manager': typeof AuthedAdminContentFileManagerRoute;
  '/_authed/_admin/content/projects': typeof AuthedAdminContentProjectsRoute;
  '/_authed/_admin/distro/history': typeof AuthedAdminDistroHistoryRoute;
  '/_authed/_admin/distro/upload': typeof AuthedAdminDistroUploadRoute;
  '/_authed/_admin/manage/addresses': typeof AuthedAdminManageAddressesRoute;
  '/_authed/_admin/manage/countries': typeof AuthedAdminManageCountriesRoute;
  '/_authed/_admin/manage/currencies': typeof AuthedAdminManageCurrenciesRoute;
  '/_authed/_admin/manage/customers': typeof AuthedAdminManageCustomersRoute;
  '/_authed/_admin/manage/line-of-business': typeof AuthedAdminManageLineOfBusinessRoute;
  '/_authed/_admin/manage/locations': typeof AuthedAdminManageLocationsRoute;
  '/_authed/_admin/manage/merchandisers': typeof AuthedAdminManageMerchandisersRoute;
  '/_authed/_admin/manage/plants': typeof AuthedAdminManagePlantsRoute;
  '/_authed/_admin/manage/products': typeof AuthedAdminManageProductsRoute;
  '/_authed/_admin/manage/programs': typeof AuthedAdminManageProgramsRoute;
  '/_authed/_admin/manage/subprograms': typeof AuthedAdminManageSubprogramsRoute;
  '/_authed/_admin/manage/users': typeof AuthedAdminManageUsersRoute;
  '/_authed/_admin/reports/emails': typeof AuthedAdminReportsEmailsRoute;
  '/_authed/_admin/reports/location-feed': typeof AuthedAdminReportsLocationFeedRoute;
  '/_authed/_admin/reports/products': typeof AuthedAdminReportsProductsRoute;
  '/_authed/_admin/reports/supportRequests': typeof AuthedAdminReportsSupportRequestsRoute;
  '/_authed/_admin/reports/users': typeof AuthedAdminReportsUsersRoute;
  '/_authed/cart/$cartId/confirmation': typeof AuthedCartCartIdConfirmationRoute;
  '/_authed/_admin/approvals/location_/$userId': typeof AuthedAdminApprovalsLocationUserIdRoute;
  '/_authed/_admin/approvals/orders_/$poNumber': typeof AuthedAdminApprovalsOrdersPoNumberRoute;
  '/_authed/_admin/content/announcements_/$announcementId': typeof AuthedAdminContentAnnouncementsAnnouncementIdRoute;
  '/_authed/_admin/content/announcements_/add': typeof AuthedAdminContentAnnouncementsAddRoute;
  '/_authed/_admin/content/faq_/$faqId': typeof AuthedAdminContentFaqFaqIdRoute;
  '/_authed/_admin/content/faq_/add': typeof AuthedAdminContentFaqAddRoute;
  '/_authed/_admin/content/file-manager_/detail': typeof AuthedAdminContentFileManagerDetailRoute;
  '/_authed/_admin/content/file-manager_/upload': typeof AuthedAdminContentFileManagerUploadRoute;
  '/_authed/_admin/manage/addresses_/$addressBookId': typeof AuthedAdminManageAddressesAddressBookIdRoute;
  '/_authed/_admin/manage/countries_/$countryCode': typeof AuthedAdminManageCountriesCountryCodeRoute;
  '/_authed/_admin/manage/countries_/add': typeof AuthedAdminManageCountriesAddRoute;
  '/_authed/_admin/manage/customers_/$customerCode': typeof AuthedAdminManageCustomersCustomerCodeRoute;
  '/_authed/_admin/manage/customers_/add': typeof AuthedAdminManageCustomersAddRoute;
  '/_authed/_admin/manage/merchandisers_/$locationId': typeof AuthedAdminManageMerchandisersLocationIdRoute;
  '/_authed/_admin/manage/merchandisers_/add': typeof AuthedAdminManageMerchandisersAddRoute;
  '/_authed/_admin/manage/products_/$itemId': typeof AuthedAdminManageProductsItemIdRoute;
  '/_authed/_admin/manage/products_/add': typeof AuthedAdminManageProductsAddRoute;
  '/_authed/_admin/manage/programs_/$programId': typeof AuthedAdminManageProgramsProgramIdRoute;
  '/_authed/_admin/manage/programs_/add': typeof AuthedAdminManageProgramsAddRoute;
  '/_authed/_admin/manage/subprograms_/add': typeof AuthedAdminManageSubprogramsAddRoute;
  '/_authed/_admin/manage/users_/$userName': typeof AuthedAdminManageUsersUserNameRoute;
  '/_authed/_admin/manage/users_/add': typeof AuthedAdminManageUsersAddRoute;
  '/_authed/_admin/reports/emails_/$emailId': typeof AuthedAdminReportsEmailsEmailIdRoute;
  '/_authed/_admin/reports/location-feed_/$locationId': typeof AuthedAdminReportsLocationFeedLocationIdRoute;
  '/_authed/_admin/reports/supportRequests_/$emailId': typeof AuthedAdminReportsSupportRequestsEmailIdRoute;
  '/_authed/_admin/manage/subprograms_/$customerId_/$countryCode': typeof AuthedAdminManageSubprogramsCustomerIdCountryCodeRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | ''
    | '/not-found'
    | '/first-login'
    | '/forgot-password'
    | '/forgot-username'
    | '/login'
    | '/logout'
    | '/privacy'
    | '/reset-password'
    | '/session-expired'
    | '/address-book'
    | '/help'
    | '/profile'
    | '/returns'
    | '/'
    | '/first-login/form'
    | '/reset-password/form'
    | '/manage'
    | '/cart/$step'
    | '/orders/$poNumber'
    | '/orders/search'
    | '/returns/$orderNumber'
    | '/cart'
    | '/approvals/location'
    | '/approvals/orders'
    | '/content/announcements'
    | '/content/faq'
    | '/content/file-manager'
    | '/content/projects'
    | '/distro/history'
    | '/distro/upload'
    | '/manage/addresses'
    | '/manage/countries'
    | '/manage/currencies'
    | '/manage/customers'
    | '/manage/line-of-business'
    | '/manage/locations'
    | '/manage/merchandisers'
    | '/manage/plants'
    | '/manage/products'
    | '/manage/programs'
    | '/manage/subprograms'
    | '/manage/users'
    | '/reports/emails'
    | '/reports/location-feed'
    | '/reports/products'
    | '/reports/supportRequests'
    | '/reports/users'
    | '/cart/$cartId/confirmation'
    | '/approvals/location/$userId'
    | '/approvals/orders/$poNumber'
    | '/content/announcements/$announcementId'
    | '/content/announcements/add'
    | '/content/faq/$faqId'
    | '/content/faq/add'
    | '/content/file-manager/detail'
    | '/content/file-manager/upload'
    | '/manage/addresses/$addressBookId'
    | '/manage/countries/$countryCode'
    | '/manage/countries/add'
    | '/manage/customers/$customerCode'
    | '/manage/customers/add'
    | '/manage/merchandisers/$locationId'
    | '/manage/merchandisers/add'
    | '/manage/products/$itemId'
    | '/manage/products/add'
    | '/manage/programs/$programId'
    | '/manage/programs/add'
    | '/manage/subprograms/add'
    | '/manage/users/$userName'
    | '/manage/users/add'
    | '/reports/emails/$emailId'
    | '/reports/location-feed/$locationId'
    | '/reports/supportRequests/$emailId'
    | '/manage/subprograms/$customerId/$countryCode';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | ''
    | '/not-found'
    | '/first-login'
    | '/forgot-password'
    | '/forgot-username'
    | '/login'
    | '/logout'
    | '/privacy'
    | '/reset-password'
    | '/session-expired'
    | '/address-book'
    | '/help'
    | '/profile'
    | '/returns'
    | '/'
    | '/first-login/form'
    | '/reset-password/form'
    | '/manage'
    | '/cart/$step'
    | '/orders/$poNumber'
    | '/orders/search'
    | '/returns/$orderNumber'
    | '/cart'
    | '/approvals/location'
    | '/approvals/orders'
    | '/content/announcements'
    | '/content/faq'
    | '/content/file-manager'
    | '/content/projects'
    | '/distro/history'
    | '/distro/upload'
    | '/manage/addresses'
    | '/manage/countries'
    | '/manage/currencies'
    | '/manage/customers'
    | '/manage/line-of-business'
    | '/manage/locations'
    | '/manage/merchandisers'
    | '/manage/plants'
    | '/manage/products'
    | '/manage/programs'
    | '/manage/subprograms'
    | '/manage/users'
    | '/reports/emails'
    | '/reports/location-feed'
    | '/reports/products'
    | '/reports/supportRequests'
    | '/reports/users'
    | '/cart/$cartId/confirmation'
    | '/approvals/location/$userId'
    | '/approvals/orders/$poNumber'
    | '/content/announcements/$announcementId'
    | '/content/announcements/add'
    | '/content/faq/$faqId'
    | '/content/faq/add'
    | '/content/file-manager/detail'
    | '/content/file-manager/upload'
    | '/manage/addresses/$addressBookId'
    | '/manage/countries/$countryCode'
    | '/manage/countries/add'
    | '/manage/customers/$customerCode'
    | '/manage/customers/add'
    | '/manage/merchandisers/$locationId'
    | '/manage/merchandisers/add'
    | '/manage/products/$itemId'
    | '/manage/products/add'
    | '/manage/programs/$programId'
    | '/manage/programs/add'
    | '/manage/subprograms/add'
    | '/manage/users/$userName'
    | '/manage/users/add'
    | '/reports/emails/$emailId'
    | '/reports/location-feed/$locationId'
    | '/reports/supportRequests/$emailId'
    | '/manage/subprograms/$customerId/$countryCode';
  id:
    | '__root__'
    | '/_authed'
    | '/_authed/_admin'
    | '/(errors)/not-found'
    | '/(public)/first-login'
    | '/(public)/forgot-password'
    | '/(public)/forgot-username'
    | '/(public)/login'
    | '/(public)/logout'
    | '/(public)/privacy'
    | '/(public)/reset-password'
    | '/(public)/session-expired'
    | '/_authed/address-book'
    | '/_authed/help'
    | '/_authed/profile'
    | '/_authed/returns'
    | '/_authed/'
    | '/(public)/first-login_/form'
    | '/(public)/reset-password_/form'
    | '/_authed/_admin/manage'
    | '/_authed/cart/$step'
    | '/_authed/orders/$poNumber'
    | '/_authed/orders/search'
    | '/_authed/returns_/$orderNumber'
    | '/_authed/cart/'
    | '/_authed/_admin/approvals/location'
    | '/_authed/_admin/approvals/orders'
    | '/_authed/_admin/content/announcements'
    | '/_authed/_admin/content/faq'
    | '/_authed/_admin/content/file-manager'
    | '/_authed/_admin/content/projects'
    | '/_authed/_admin/distro/history'
    | '/_authed/_admin/distro/upload'
    | '/_authed/_admin/manage/addresses'
    | '/_authed/_admin/manage/countries'
    | '/_authed/_admin/manage/currencies'
    | '/_authed/_admin/manage/customers'
    | '/_authed/_admin/manage/line-of-business'
    | '/_authed/_admin/manage/locations'
    | '/_authed/_admin/manage/merchandisers'
    | '/_authed/_admin/manage/plants'
    | '/_authed/_admin/manage/products'
    | '/_authed/_admin/manage/programs'
    | '/_authed/_admin/manage/subprograms'
    | '/_authed/_admin/manage/users'
    | '/_authed/_admin/reports/emails'
    | '/_authed/_admin/reports/location-feed'
    | '/_authed/_admin/reports/products'
    | '/_authed/_admin/reports/supportRequests'
    | '/_authed/_admin/reports/users'
    | '/_authed/cart/$cartId/confirmation'
    | '/_authed/_admin/approvals/location_/$userId'
    | '/_authed/_admin/approvals/orders_/$poNumber'
    | '/_authed/_admin/content/announcements_/$announcementId'
    | '/_authed/_admin/content/announcements_/add'
    | '/_authed/_admin/content/faq_/$faqId'
    | '/_authed/_admin/content/faq_/add'
    | '/_authed/_admin/content/file-manager_/detail'
    | '/_authed/_admin/content/file-manager_/upload'
    | '/_authed/_admin/manage/addresses_/$addressBookId'
    | '/_authed/_admin/manage/countries_/$countryCode'
    | '/_authed/_admin/manage/countries_/add'
    | '/_authed/_admin/manage/customers_/$customerCode'
    | '/_authed/_admin/manage/customers_/add'
    | '/_authed/_admin/manage/merchandisers_/$locationId'
    | '/_authed/_admin/manage/merchandisers_/add'
    | '/_authed/_admin/manage/products_/$itemId'
    | '/_authed/_admin/manage/products_/add'
    | '/_authed/_admin/manage/programs_/$programId'
    | '/_authed/_admin/manage/programs_/add'
    | '/_authed/_admin/manage/subprograms_/add'
    | '/_authed/_admin/manage/users_/$userName'
    | '/_authed/_admin/manage/users_/add'
    | '/_authed/_admin/reports/emails_/$emailId'
    | '/_authed/_admin/reports/location-feed_/$locationId'
    | '/_authed/_admin/reports/supportRequests_/$emailId'
    | '/_authed/_admin/manage/subprograms_/$customerId_/$countryCode';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  AuthedRouteRoute: typeof AuthedRouteRouteWithChildren;
  errorsNotFoundRoute: typeof errorsNotFoundRoute;
  publicFirstLoginRoute: typeof publicFirstLoginRoute;
  publicForgotPasswordRoute: typeof publicForgotPasswordRoute;
  publicForgotUsernameRoute: typeof publicForgotUsernameRoute;
  publicLoginRoute: typeof publicLoginRoute;
  publicLogoutRoute: typeof publicLogoutRoute;
  publicPrivacyRoute: typeof publicPrivacyRoute;
  publicResetPasswordRoute: typeof publicResetPasswordRoute;
  publicSessionExpiredRoute: typeof publicSessionExpiredRoute;
  publicFirstLoginFormRoute: typeof publicFirstLoginFormRoute;
  publicResetPasswordFormRoute: typeof publicResetPasswordFormRoute;
}

const rootRouteChildren: RootRouteChildren = {
  AuthedRouteRoute: AuthedRouteRouteWithChildren,
  errorsNotFoundRoute: errorsNotFoundRoute,
  publicFirstLoginRoute: publicFirstLoginRoute,
  publicForgotPasswordRoute: publicForgotPasswordRoute,
  publicForgotUsernameRoute: publicForgotUsernameRoute,
  publicLoginRoute: publicLoginRoute,
  publicLogoutRoute: publicLogoutRoute,
  publicPrivacyRoute: publicPrivacyRoute,
  publicResetPasswordRoute: publicResetPasswordRoute,
  publicSessionExpiredRoute: publicSessionExpiredRoute,
  publicFirstLoginFormRoute: publicFirstLoginFormRoute,
  publicResetPasswordFormRoute: publicResetPasswordFormRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authed",
        "/(errors)/not-found",
        "/(public)/first-login",
        "/(public)/forgot-password",
        "/(public)/forgot-username",
        "/(public)/login",
        "/(public)/logout",
        "/(public)/privacy",
        "/(public)/reset-password",
        "/(public)/session-expired",
        "/(public)/first-login_/form",
        "/(public)/reset-password_/form"
      ]
    },
    "/_authed": {
      "filePath": "_authed/route.tsx",
      "children": [
        "/_authed/_admin",
        "/_authed/address-book",
        "/_authed/help",
        "/_authed/profile",
        "/_authed/returns",
        "/_authed/",
        "/_authed/cart/$step",
        "/_authed/orders/$poNumber",
        "/_authed/orders/search",
        "/_authed/returns_/$orderNumber",
        "/_authed/cart/",
        "/_authed/cart/$cartId/confirmation"
      ]
    },
    "/_authed/_admin": {
      "filePath": "_authed/_admin/route.tsx",
      "parent": "/_authed",
      "children": [
        "/_authed/_admin/manage",
        "/_authed/_admin/approvals/location",
        "/_authed/_admin/approvals/orders",
        "/_authed/_admin/content/announcements",
        "/_authed/_admin/content/faq",
        "/_authed/_admin/content/file-manager",
        "/_authed/_admin/content/projects",
        "/_authed/_admin/distro/history",
        "/_authed/_admin/distro/upload",
        "/_authed/_admin/reports/emails",
        "/_authed/_admin/reports/location-feed",
        "/_authed/_admin/reports/products",
        "/_authed/_admin/reports/supportRequests",
        "/_authed/_admin/reports/users",
        "/_authed/_admin/approvals/location_/$userId",
        "/_authed/_admin/approvals/orders_/$poNumber",
        "/_authed/_admin/content/announcements_/$announcementId",
        "/_authed/_admin/content/announcements_/add",
        "/_authed/_admin/content/faq_/$faqId",
        "/_authed/_admin/content/faq_/add",
        "/_authed/_admin/content/file-manager_/detail",
        "/_authed/_admin/content/file-manager_/upload",
        "/_authed/_admin/reports/emails_/$emailId",
        "/_authed/_admin/reports/location-feed_/$locationId",
        "/_authed/_admin/reports/supportRequests_/$emailId"
      ]
    },
    "/(errors)/not-found": {
      "filePath": "(errors)/not-found.tsx"
    },
    "/(public)/first-login": {
      "filePath": "(public)/first-login.tsx"
    },
    "/(public)/forgot-password": {
      "filePath": "(public)/forgot-password.tsx"
    },
    "/(public)/forgot-username": {
      "filePath": "(public)/forgot-username.tsx"
    },
    "/(public)/login": {
      "filePath": "(public)/login.tsx"
    },
    "/(public)/logout": {
      "filePath": "(public)/logout.tsx"
    },
    "/(public)/privacy": {
      "filePath": "(public)/privacy.tsx"
    },
    "/(public)/reset-password": {
      "filePath": "(public)/reset-password.tsx"
    },
    "/(public)/session-expired": {
      "filePath": "(public)/session-expired.tsx"
    },
    "/_authed/address-book": {
      "filePath": "_authed/address-book.tsx",
      "parent": "/_authed"
    },
    "/_authed/help": {
      "filePath": "_authed/help.tsx",
      "parent": "/_authed"
    },
    "/_authed/profile": {
      "filePath": "_authed/profile.tsx",
      "parent": "/_authed"
    },
    "/_authed/returns": {
      "filePath": "_authed/returns.tsx",
      "parent": "/_authed"
    },
    "/_authed/": {
      "filePath": "_authed/index.tsx",
      "parent": "/_authed"
    },
    "/(public)/first-login_/form": {
      "filePath": "(public)/first-login_.form.tsx"
    },
    "/(public)/reset-password_/form": {
      "filePath": "(public)/reset-password_.form.tsx"
    },
    "/_authed/_admin/manage": {
      "filePath": "_authed/_admin/manage.tsx",
      "parent": "/_authed/_admin",
      "children": [
        "/_authed/_admin/manage/addresses",
        "/_authed/_admin/manage/countries",
        "/_authed/_admin/manage/currencies",
        "/_authed/_admin/manage/customers",
        "/_authed/_admin/manage/line-of-business",
        "/_authed/_admin/manage/locations",
        "/_authed/_admin/manage/merchandisers",
        "/_authed/_admin/manage/plants",
        "/_authed/_admin/manage/products",
        "/_authed/_admin/manage/programs",
        "/_authed/_admin/manage/subprograms",
        "/_authed/_admin/manage/users",
        "/_authed/_admin/manage/addresses_/$addressBookId",
        "/_authed/_admin/manage/countries_/$countryCode",
        "/_authed/_admin/manage/countries_/add",
        "/_authed/_admin/manage/customers_/$customerCode",
        "/_authed/_admin/manage/customers_/add",
        "/_authed/_admin/manage/merchandisers_/$locationId",
        "/_authed/_admin/manage/merchandisers_/add",
        "/_authed/_admin/manage/products_/$itemId",
        "/_authed/_admin/manage/products_/add",
        "/_authed/_admin/manage/programs_/$programId",
        "/_authed/_admin/manage/programs_/add",
        "/_authed/_admin/manage/subprograms_/add",
        "/_authed/_admin/manage/users_/$userName",
        "/_authed/_admin/manage/users_/add",
        "/_authed/_admin/manage/subprograms_/$customerId_/$countryCode"
      ]
    },
    "/_authed/cart/$step": {
      "filePath": "_authed/cart.$step.tsx",
      "parent": "/_authed"
    },
    "/_authed/orders/$poNumber": {
      "filePath": "_authed/orders.$poNumber.tsx",
      "parent": "/_authed"
    },
    "/_authed/orders/search": {
      "filePath": "_authed/orders.search.tsx",
      "parent": "/_authed"
    },
    "/_authed/returns_/$orderNumber": {
      "filePath": "_authed/returns_.$orderNumber.tsx",
      "parent": "/_authed"
    },
    "/_authed/cart/": {
      "filePath": "_authed/cart.index.tsx",
      "parent": "/_authed"
    },
    "/_authed/_admin/approvals/location": {
      "filePath": "_authed/_admin/approvals.location.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/approvals/orders": {
      "filePath": "_authed/_admin/approvals.orders.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/announcements": {
      "filePath": "_authed/_admin/content.announcements.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/faq": {
      "filePath": "_authed/_admin/content.faq.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/file-manager": {
      "filePath": "_authed/_admin/content.file-manager.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/projects": {
      "filePath": "_authed/_admin/content.projects.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/distro/history": {
      "filePath": "_authed/_admin/distro.history.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/distro/upload": {
      "filePath": "_authed/_admin/distro.upload.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/manage/addresses": {
      "filePath": "_authed/_admin/manage.addresses.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/countries": {
      "filePath": "_authed/_admin/manage.countries.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/currencies": {
      "filePath": "_authed/_admin/manage.currencies.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/customers": {
      "filePath": "_authed/_admin/manage.customers.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/line-of-business": {
      "filePath": "_authed/_admin/manage.line-of-business.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/locations": {
      "filePath": "_authed/_admin/manage.locations.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/merchandisers": {
      "filePath": "_authed/_admin/manage.merchandisers.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/plants": {
      "filePath": "_authed/_admin/manage.plants.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/products": {
      "filePath": "_authed/_admin/manage.products.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/programs": {
      "filePath": "_authed/_admin/manage.programs.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/subprograms": {
      "filePath": "_authed/_admin/manage.subprograms.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/users": {
      "filePath": "_authed/_admin/manage.users.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/reports/emails": {
      "filePath": "_authed/_admin/reports/emails.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/reports/location-feed": {
      "filePath": "_authed/_admin/reports/location-feed.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/reports/products": {
      "filePath": "_authed/_admin/reports/products.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/reports/supportRequests": {
      "filePath": "_authed/_admin/reports/supportRequests.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/reports/users": {
      "filePath": "_authed/_admin/reports/users.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/cart/$cartId/confirmation": {
      "filePath": "_authed/cart.$cartId.confirmation.tsx",
      "parent": "/_authed"
    },
    "/_authed/_admin/approvals/location_/$userId": {
      "filePath": "_authed/_admin/approvals.location_.$userId.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/approvals/orders_/$poNumber": {
      "filePath": "_authed/_admin/approvals.orders_.$poNumber.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/announcements_/$announcementId": {
      "filePath": "_authed/_admin/content.announcements_.$announcementId.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/announcements_/add": {
      "filePath": "_authed/_admin/content.announcements_.add.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/faq_/$faqId": {
      "filePath": "_authed/_admin/content.faq_.$faqId.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/faq_/add": {
      "filePath": "_authed/_admin/content.faq_.add.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/file-manager_/detail": {
      "filePath": "_authed/_admin/content.file-manager_.detail.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/file-manager_/upload": {
      "filePath": "_authed/_admin/content.file-manager_.upload.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/manage/addresses_/$addressBookId": {
      "filePath": "_authed/_admin/manage.addresses_.$addressBookId.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/countries_/$countryCode": {
      "filePath": "_authed/_admin/manage.countries_.$countryCode.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/countries_/add": {
      "filePath": "_authed/_admin/manage.countries_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/customers_/$customerCode": {
      "filePath": "_authed/_admin/manage.customers_.$customerCode.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/customers_/add": {
      "filePath": "_authed/_admin/manage.customers_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/merchandisers_/$locationId": {
      "filePath": "_authed/_admin/manage.merchandisers_.$locationId.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/merchandisers_/add": {
      "filePath": "_authed/_admin/manage.merchandisers_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/products_/$itemId": {
      "filePath": "_authed/_admin/manage.products_.$itemId.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/products_/add": {
      "filePath": "_authed/_admin/manage.products_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/programs_/$programId": {
      "filePath": "_authed/_admin/manage.programs_.$programId.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/programs_/add": {
      "filePath": "_authed/_admin/manage.programs_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/subprograms_/add": {
      "filePath": "_authed/_admin/manage.subprograms_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/users_/$userName": {
      "filePath": "_authed/_admin/manage.users_.$userName.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/users_/add": {
      "filePath": "_authed/_admin/manage.users_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/reports/emails_/$emailId": {
      "filePath": "_authed/_admin/reports/emails_.$emailId.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/reports/location-feed_/$locationId": {
      "filePath": "_authed/_admin/reports/location-feed_.$locationId.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/reports/supportRequests_/$emailId": {
      "filePath": "_authed/_admin/reports/supportRequests_.$emailId.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/manage/subprograms_/$customerId_/$countryCode": {
      "filePath": "_authed/_admin/manage.subprograms_.$customerId_.$countryCode.tsx",
      "parent": "/_authed/_admin/manage"
    }
  }
}
ROUTE_MANIFEST_END */
