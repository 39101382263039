import { useMemo } from 'react';
import { AppShell, Group, rem, ScrollArea } from '@mantine/core';
import { useDidUpdate, useWindowScroll } from '@mantine/hooks';
import { Outlet, ScrollRestoration, useLocation } from '@tanstack/react-router';
import type { AppShellProps, GroupProps } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import { AnnouncementMenu } from '@apple/features/announcements/components/AnnouncementMenu';
import { useUserActiveAnnouncements } from '@apple/features/announcements/hooks/useUserAnnouncements';
import { useAuthContext } from '@apple/features/auth';
import { Toolbar } from '@apple/ui/data-table/controls/Toolbar';
import { AppleFooter } from '@apple/ui/layouts/components/apple-footer';
import { SidebarMenu } from '@apple/ui/layouts/components/sidebar-menu';
import { LoadingOverlay } from '@apple/ui/loading-overlay';
import { AvatarMenu, ToggleColorSchemeButton, useColorScheme } from '@apple/ui/shell';
import { AppleLogo } from '@apple/ui/shell/components/AppleLogo';
import { ToggleLeftSidebarButton } from '@apple/ui/shell/components/ToggleLeftSidebarButton';
import { ToggleRightSidebarButton } from '@apple/ui/shell/components/ToggleRightSidebarButton';
import {
	useLeftSidebarCollapsed,
	useLeftSidebarProps,
} from '@apple/ui/shell/contexts/left-sidebar';
import {
	useRightSidebarCollapsed,
	useRightSidebarGetComponent,
	useRightSidebarProps,
} from '@apple/ui/shell/contexts/right-sidebar';
import { getEnv } from '@apple/utils/config/env';
import type { MenuItemGroup } from '@apple/ui/layouts/components/menu-item';

const env = getEnv();

type Props = PropsWithChildren<{
	menuItems?: MenuItemGroup[];
	loading?: boolean;
	cartMenu?: React.ReactNode;
}>;

export function AppleLayout({ children, loading = false, cartMenu, menuItems = [] }: Props) {
	const { authenticated } = useAuthContext();
	const { isDark } = useColorScheme();
	const leftSidebar = useLeftSidebarProps();
	const leftSidebarCollapsed = useLeftSidebarCollapsed();
	const rightSidebarProps = useRightSidebarProps();
	const rightSidebarCollapsed = useRightSidebarCollapsed();
	const rightSidebar = useRightSidebarGetComponent();
	const location = useLocation();
	useDidUpdate(() => {
		if (leftSidebarCollapsed.isSmallerThanBreakpoint && !leftSidebarCollapsed.isCollapsed) {
			leftSidebarCollapsed.close();
		}
	}, [location]);

	const [scrollPosition] = useWindowScroll();
	const opacity = calculateScrollOpacity(scrollPosition.y, 44, 0.15);

	const headerVisible = !authenticated || leftSidebarCollapsed.isCollapsed;
	const leftSidebarVisible = menuItems.length > 0;

	const appshellProps = useMemo<AppShellProps>(
		() => ({
			header: {
				height: headerVisible ? rem(44) : 0,
			},
			navbar: leftSidebarVisible ? leftSidebar : undefined,
			aside: !rightSidebarCollapsed.hasComponent ? undefined : rightSidebarProps,
			layout: 'alt',
		}),
		[
			headerVisible,
			leftSidebarVisible,
			leftSidebar,
			rightSidebarProps,
			rightSidebarCollapsed.hasComponent,
		],
	);

	return (
		<AppShell {...appshellProps} disabled={!authenticated}>
			<AppShell.Header
				style={{
					'--header-opacity': opacity,
				}}
				display={headerVisible ? undefined : 'none'}
			>
				<HeaderContent hideToggleButtonWhen='expanded' cartMenu={cartMenu} />
			</AppShell.Header>

			<AppShell.Navbar display={leftSidebarVisible ? undefined : 'none'}>
				<AppShell.Section pt={0}>
					<HeaderContent hideToggleButtonWhen='collapsed' cartMenu={cartMenu} />
				</AppShell.Section>

				<AppShell.Section pl='xs' pr='lg' pt='xs'>
					<AvatarMenu variant={isDark ? 'dark' : 'light'} fullWidth />
				</AppShell.Section>

				<AppShell.Section grow component={ScrollArea} p='lg' pl='xs' pt='xs' pb={0}>
					<SidebarMenu size='sm' menuItems={menuItems} />
				</AppShell.Section>

				<AppShell.Section p='lg' pl='xs'>
					<AppleFooter />
				</AppShell.Section>
			</AppShell.Navbar>

			<AppShell.Aside display={!rightSidebarCollapsed.hasComponent ? 'none' : undefined}>
				<AppShell.Section>
					<Toolbar
						rightSection={() => [
							rightSidebarCollapsed.isSmallerThanBreakpoint ? undefined : (
								<ToggleRightSidebarButton key='right-sidebar-toggle' />
							),
						]}
					/>
				</AppShell.Section>
				<AppShell.Section grow component={ScrollArea}>
					{rightSidebar}
				</AppShell.Section>
			</AppShell.Aside>

			<AppShell.Main mt={headerVisible ? rem(-44) : undefined}>
				<LoadingOverlay
					visible={loading}
					debounce={500}
					name='layout'
					zIndex={100}
					left={leftSidebarCollapsed.isCollapsed ? 0 : leftSidebar.width}
					right={rightSidebarCollapsed.isCollapsed ? (rightSidebarProps?.width ?? 0) : 0}
					loaderProps={{
						pos: 'fixed',
					}}
					overlayProps={{
						fixed: true,
					}}
				/>
				<ScrollRestoration />
				{children ?? <Outlet />}
			</AppShell.Main>
		</AppShell>
	);
}

function HeaderContent({
	hideToggleButtonWhen,
	cartMenu,
}: {
	hideToggleButtonWhen?: 'collapsed' | 'expanded';
	cartMenu?: React.ReactNode;
}) {
	const { isDark } = useColorScheme();
	const { authenticated } = useAuthContext();

	return (
		<Group
			gap={0}
			justify='space-between'
			align='baseline'
			wrap='nowrap'
			pt={rem(3)}
			px={rem(3)}
		>
			<Group gap={0} justify='space-between' align='baseline' wrap='nowrap'>
				{authenticated && (
					<>
						<ToggleLeftSidebarButton hideWhen={hideToggleButtonWhen} />
						<AppleLogo to='/' label={env.APPLE_APP_TITLE} />
					</>
				)}
			</Group>
			<HeaderButtons
				justify='flex-end'
				variant={isDark ? 'light' : 'dark'}
				cartMenu={cartMenu}
			/>
		</Group>
	);
}

type HeaderButtonsProps = Omit<GroupProps, 'c'> & {
	cartMenu?: React.ReactNode;
};

function HeaderButtons({ cartMenu, ...groupProps }: HeaderButtonsProps) {
	const { authenticated } = useAuthContext();

	const userAnnouncements = useUserActiveAnnouncements();

	return (
		<Group pr='xs' wrap='nowrap' gap={'xs'} {...groupProps}>
			{authenticated && cartMenu}
			{!authenticated && <ToggleColorSchemeButton />}
			{authenticated && (
				<AnnouncementMenu
					loading={userAnnouncements.isLoading}
					values={userAnnouncements.data ?? null}
				/>
			)}
		</Group>
	);
}

function calculateScrollOpacity(scrollY: number, maxScrollY: number, maxOpacity: number): number {
	const minOpacity = 0;

	// Calculate a normalized scroll position between 0 and 1
	const t = Math.min(Math.max(scrollY / maxScrollY, 0), 1);

	// Linearly interpolate between 0 and maxOpacity
	return minOpacity + t * (maxOpacity - minOpacity);
}
