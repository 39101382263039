import { z } from 'zod';

import type { CountryCode } from '@apple/utils/globalization';
import type { PaginatedApiFilters } from '@apple/utils/pagination/hooks/usePaginatedApi';
import type { Guid } from '@apple/utils/primitive';

export const addressBookKeys = {
	all: ['address-book'],
	filtered: (filter: PaginatedApiFilters<object>) => ['address-book', filter],
} as const;

export const addressFieldsSchema = z.object({
	country: z.string(),
	company: z.string().optional().nullable(),
	email: z.string(),
	title: z.string().optional().nullable(),
	firstName: z.string(),
	middleName: z.string().optional().nullable(),
	lastName: z.string(),
	phone: z.string(),
	extension: z.string().optional().nullable(),
	state: z.string(),
	street1: z.string(),
	street2: z.string().optional().nullable(),
	street3: z.string().optional().nullable(),
	zip: z.string(),
	tinCode: z.string().optional().nullable(),
	city: z.string(),
});

export type Address = {
	addressId: Guid;
	addressClassification: AddressClassification;
	recipientName: string | null;
	attentionTo: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string | null;
	city: string;
	stateOrProvince: string;
	countryCode: CountryCode;
	postalCode: string;
	isDeleted: boolean;
};

// TODO: Reconsider this when doing Task #57910
export type AddressBookItem = {
	addressBookId: string;
	userId: number;
	addressName: string;
	countryTaxId: string | null;
	phoneNumber: string;
	isDeleted: boolean;
	shippingAddress: Address;
	internationalShippingAddress: Address | null;
};

export type OrderShipToAddress = AddressBookItem & {
	bulkOrderId: Guid;
	shipToKey: Guid;
	saveToAddressBook: boolean;
};

export type AddressClassification = 'Residential' | 'BusinessCommercial' | 'POBox';

export enum CountryAddressRequirement {
	Local = 0,
	LocalAndInternational = 1,
}
