import axios from 'axios';

import type { PaginatedListDto } from '@apple/utils/pagination';

import { productLineSchema } from '../models/product-line';
import type { ProductLine } from '../models/product-line';

/** @see Manzanita.Web.Features.Catalog.Categories.ProductCategoriesController */
export async function getById(id: number): Promise<ProductLine> {
	// TODO: These are actually product categories
	const response = await axios.get(`/api/management/product-categories/${id}`);

	return productLineSchema.parse(response.data);
}

/**
 * Get a list of all product lines of business.
 * @see Manzanita.Web.Features.Catalog.Categories.ProductCategoriesODataController
 */
export async function getProductLineGridData(): Promise<ProductLine[]> {
	// TODO: Zod schema for pagination - also this is technically an odata api for categories
	const response = await axios.get<PaginatedListDto<ProductLine>>(
		'/odata/management/product-categories',
	);

	return response.data.items;
}

/** @see AppleBrandedPrograms.Web.Features.LineOfBusiness.LineOfBusinessController */
export async function getProductLines(): Promise<ProductLine[]> {
	const response = await axios.get('/api/line-of-business/');

	return productLineSchema.array().parse(response.data);
}
