import { useMemo } from 'react';

import {
	Address,
	AnnouncementOutlineIcon,
	Book,
	Calendar,
	Countries,
	Customers,
	DashboardIcon,
	EmailIcon,
	Faq,
	FileManagerIcon,
	Location,
	LocationApproval,
	Merchandisers,
	OrderSearchIcon,
	Products,
	Programs,
	ProjectsIcon,
	ReturnsIcon,
	ShoppingBagOutline,
	SubPrograms,
	Success,
	SupportIcon,
	Upload,
	Users,
} from '@apple/assets/icons';
import { useAuthContext } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import type { MenuItem, MenuItemGroup } from '@apple/ui/layouts/components/menu-item';

export function useAppleChannelNavMenu() {
	const { t } = useTranslation('common');
	const { filterByPermissions } = useAuthContext();

	const menuItems = useMemo(() => {
		return filterByPermissions<MenuItemGroup>([
			{
				requireAuth: true,
				items: filterByPermissions<MenuItem>([
					// FIXME: Merge the two dashboard routes into one
					{
						label: t('mainMenu.dashboard'),
						icon: DashboardIcon,
						to: '/',
						requireAuth: true,
					},
					{
						label: t('mainMenu.shop'),
						icon: ShoppingBagOutline,
						to: '/cart',
						requireAuth: true,
					},
					{
						label: t('mainMenu.orders'),
						icon: OrderSearchIcon,
						to: '/orders/search',
						requireAuth: true,
					},
					{
						label: t('mainMenu.returns'),
						icon: ReturnsIcon,
						to: '/returns',
						requireAuth: true,
						requirePermission: 'AppleBrandedPrograms.Security.Features.Returns',
					},
					{
						label: t('shell.addressBook'),
						icon: Book,
						to: '/address-book',
						requireAuth: true,
					},
					{
						label: t('mainMenu.help'),
						icon: SupportIcon,
						to: '/help',
						requireAuth: true,
					},
				]),
			},
			{
				label: t('mainMenu.admin.approvals.label'),
				requireAuth: true,
				items: filterByPermissions<MenuItem>([
					{
						icon: Success,
						label: t('mainMenu.admin.approvals.orderApprovals'),
						to: '/approvals/orders',
						requirePermission: 'Manzanita.Security.Features.OrderManagement.Read',
					},
					{
						icon: LocationApproval,
						label: t('mainMenu.admin.approvals.locationApprovals'),
						to: '/approvals/location',
						requirePermission:
							'AppleBrandedPrograms.Security.Features.LocationApprovalManagement',
					},
				]),
			},
			{
				label: t('mainMenu.admin.distroOrders.label'),
				requirePermission: 'Manzanita.Security.Orders.CanPlaceDistroOrders',
				items: filterByPermissions<MenuItem>([
					{
						icon: Upload,
						label: t('mainMenu.admin.distroOrders.upload'),
						to: '/distro/upload',
						requirePermission: 'Manzanita.Security.Orders.CanPlaceDistroOrders',
					},
					{
						icon: Calendar,
						label: t('mainMenu.admin.distroOrders.history'),
						to: '/distro/history',
						requirePermission: 'Manzanita.Security.Features.DistroOrderHistory',
					},
				]),
			},
			{
				label: t('mainMenu.admin.manage.label'),
				requirePermission: 'Manzanita.Security.Features.Snapshot',
				items: filterByPermissions<MenuItem>([
					{
						icon: Address,
						label: t('mainMenu.admin.manage.addresses'),
						to: '/manage/addresses',
						requirePermission:
							'AppleBranded.Security.ShipToAddress.CanEditOtherUsersAddresses',
					},
					// {
					// 	icon: Countries,
					// 	label: t('mainMenu.admin.manage.countries'),
					// 	to: '/manage/countries',
					// 	requirePermission: 'Manzanita.Security.Features.CountryManagement.Read',
					// 	disabled: true,
					// },
					// {
					// 	icon: CurrenciesIcon,
					// 	label: t('mainMenu.admin.manage.currencies'),
					// 	to: '/manage/currencies',
					// 	requirePermission: 'Manzanita.Security.Features.CustomerManagement',
					// 	disabled: true,
					// },
					// {
					// 	icon: LineOfBusiness,
					// 	label: t('mainMenu.admin.manage.productLines'),
					// 	to: '/manage/line-of-business',
					// 	requirePermission:
					// 		'Manzanita.Security.Features.ProductCategoryManagement.Read',
					// 	disabled: true,
					// },
					// {
					// 	icon: Location,
					// 	label: t('mainMenu.admin.manage.locations'),
					// 	to: '/manage/locations',
					// 	requirePermission:
					// 		'AppleBrandedPrograms.Security.Features.AppleIdFeedLocationsManagement.Read',
					// 	disabled: true,
					// },
					// {
					// 	icon: Plant,
					// 	label: t('mainMenu.admin.manage.plants'),
					// 	to: '/manage/plants',
					// 	requirePermission: 'Manzanita.Security.Features.PlantManagement.Read',
					// 	disabled: true,
					// },
					{
						icon: Products,
						label: t('mainMenu.admin.manage.products'),
						to: '/manage/products',
						requirePermission: 'Manzanita.Security.Features.ProductManagement.Read',
					},
					{
						icon: Users,
						label: t('mainMenu.admin.manage.users'),
						to: '/manage/users',
						requirePermission: 'Manzanita.Security.Features.UserManagement.Read',
					},
					{
						icon: Merchandisers,
						label: t('mainMenu.admin.manage.merchandisers'),
						to: '/manage/merchandisers',
						requirePermission:
							'AppleBrandedPrograms.Security.Features.AppleMerchandisersManagement.Read',
					},
					{
						icon: Countries,
						label: t('mainMenu.admin.manage.countries'),
						to: '/manage/countries',
						requirePermission: 'Manzanita.Security.Features.CountryManagement.Read',
					},
					{
						icon: Customers,
						label: t('mainMenu.admin.manage.customers'),
						to: '/manage/customers',
						requirePermission: 'Manzanita.Security.Features.CustomerManagement',
					},
					{
						icon: Programs,
						label: t('mainMenu.admin.manage.programs'),
						to: '/manage/programs',
						requirePermission:
							'Manzanita.Security.Features.CustomerGroupManagement.Read',
					},
					{
						icon: SubPrograms,
						label: t('mainMenu.admin.manage.subPrograms'),
						to: '/manage/subprograms',
						requirePermission:
							'Manzanita.Security.Features.CustomerGroupCountryManagement.Read',
					},
				]),
			},
			{
				label: t('mainMenu.admin.content.label'),
				requirePermission: 'Manzanita.Security.Features.ContentManagement',
				items: filterByPermissions<MenuItem>([
					{
						icon: AnnouncementOutlineIcon,
						label: t('mainMenu.admin.content.announcements'),
						to: '/content/announcements',
					},
					{
						icon: Faq,
						label: t('mainMenu.admin.content.faqs'),
						to: '/content/faq',
					},
					{
						icon: ProjectsIcon,
						label: t('mainMenu.admin.content.projects'),
						to: '/content/projects',
					},
					{
						icon: FileManagerIcon,
						label: t('mainMenu.admin.content.fileManager'),
						to: '/content/file-manager',
						requirePermission: 'AppleBranded.Security.UserFileRepository.View',
					},
				]),
			},
			{
				label: t('mainMenu.admin.reports.label'),
				requirePermission: 'Manzanita.Security.Features.Reports',
				items: filterByPermissions<MenuItem>([
					{
						icon: EmailIcon,
						label: t('mainMenu.admin.reports.emails'),
						to: '/reports/emails',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
					// {
					// 	icon: Products,
					// 	label: t('mainMenu.admin.manage.products'),
					// 	to: '/reports/products',
					// 	requirePermission: 'Manzanita.Security.Features.Reports',
					// 	disabled: true,
					// },
					// {
					// 	icon: Users,
					// 	label: t('mainMenu.admin.manage.users'),
					// 	to: '/reports/users',
					// 	requirePermission: 'Manzanita.Security.Features.Reports',
					// 	disabled: true,
					// },
					{
						icon: SupportIcon,
						label: t('mainMenu.admin.reports.supportRequests'),
						to: '/reports/supportRequests',
						requirePermission: 'Manzanita.Security.Features.Reports',
					},
					{
						icon: Location,
						label: t('mainMenu.admin.reports.locationFeed'),
						to: '/reports/location-feed',
						requirePermission:
							'AppleBrandedPrograms.Security.Features.AppleIdFeedLocationsManagement.Read',
					},
				]),
			},
		]);
	}, [filterByPermissions, t]);

	return { menuItems };
}
